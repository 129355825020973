import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-web-category',
  templateUrl: './web-category.component.html',
  styleUrls: ['./web-category.component.css']
})
export class WebCategoryComponent implements OnInit {
  category = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  categoryMin = {
    max: 100,
    page: 1,
    status:true
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(){
    this.session.postRequest('category:findAllByStatus', this.categoryMin).subscribe((data: any) => {
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    }, error => {
      console.log('error:category:findAllByStatus', error);
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.categoryMin.max = event.pageSize;
    this.categoryMin.page = event.pageIndex + 1;
    this.getCategories();
  }

}
