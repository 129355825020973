<section class="" style="">

    <div class=" cardContactanos " style="max-width: 100%;  padding: 3rem 0rem;">
        <div class="row justify-content-center m-0">
          <div class="col-md-9 col-11">


            <div class="row justify-content-center g-0">
                <div class="col-md-3 ">
                    <h5 style="color: #78e2b3;">CUPONERA DIGITAL</h5>
                 <p style="font-size: .7rem; color: white;">Promueve las ofertas de tu empresa, dirigiéndolas a consumidores específicos, con estrategias de marketing digital.</p>
                </div>

                <div class="col-md-3 conten">
                    <h5 style="color: #78e2b3;">CATEGORIAS</h5>


                     <div class="overflow-auto" *ngFor="let object of list; let i = index;">
                        <a class="estilos" [routerLink]="['/coupons/category', object.id]">{{object.name}}</a>
                    </div>


                </div>

                <div class="col-md-3 conten">
                    <h5 style="color: #78e2b3;">BUSCA TU CUPÓN</h5>
                    <div class="d-flex">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="couponSearchingMin.filter" (ngModelChange)="search();" [matAutocomplete]="autoProduct">
                        <button class="btn-buscar " type="submit"><span class="material-icons" style="font-size: 18px;">search</span></button>
                        <mat-autocomplete autoActiveFirstOption #autoProduct="matAutocomplete">
                          <mat-option *ngFor="let object of couponSearchList" [value]="object.company.name + ' ' + object.promotion_description" (click)="couponSelected(object);">
                            <img class="example-option-img" aria-hidden src="{{object.img}}" height="25" style="padding-right: 15px;">
                            <span> <strong style="font-size: medium; color: #706ed9;"> {{object.company.name}} </strong> {{object.promotion_description}} </span>
                          </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-md-2 conten">
                    <h5 style="color: #78e2b3;">SÍGUENOS</h5>
                    <a href="https://www.facebook.com/cvcuponeravirtual" target="_blank"><img src="/assets/images/facebook.png" style="width: 1.5rem;" alt=""></a>&nbsp;
                    <a  href="https://www.instagram.com/cuponeravirtual" target="_blank"><img src="/assets/images/instagram.png" style="width: 1.6rem;" alt=""></a>&nbsp;
                </div>

            </div>
        </div>
          </div>
    </div>
    <div class="row justify-content-center m-0" style="background: #4e3f8c; padding: .25rem 0rem; font-size: .65rem; color: #fff;">
      2021 Derechos reservados 
    </div>
</section>
