import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';

@Component({
  selector: 'app-mis-cupones',
  templateUrl: './mis-cupones.component.html',
  styleUrls: ['./mis-cupones.component.css']
})
export class MisCuponesComponent implements OnInit {

  profileCouponMin = {
    max: 10,
    page: 1,
    status:true,
    profile:{
      id:''
    }
  }
  list:any = [];
  pageEvent: PageEvent;
  
  profile={
    id:'',
    name:'',
    user:{
      id:''
    }
  }
  

  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // this.getCupones();
    this.getProfileCoupon();
  }


/**funcionalidad para obtener el perfil de un usuario
*/
getProfile(){
  return new Promise((resolve,reject)=>{
    this.profile.user.id = this.session.getSession().user.id;
    this.session.postRequest("profile:findByUser",this.profile).subscribe((data:any)=>{
      this.profile = JSON.parse(JSON.stringify(data.object));
      resolve(true)
    },error=>{
      reject(error);
    })
  });
}


/**funcionalidad para obtener el perfil y copon de un usuario
*/
 async getProfileCoupon(){
  await this.getProfile();
  this.loadding.show(true,"Espere un momento...");
  this.profileCouponMin.profile.id = this.profile.id;
  this.session.postRequest("profileCoupon:findAllByProfile",this.profileCouponMin).subscribe((data:any)=>{
  this.list = data.object.instanceList;
  this.loadding.hide();
  },error=>{
    console.log(error);
    this.loadding.hide();
  })
}

}
