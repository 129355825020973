<app-menu></app-menu>
<div class="container downMenu">
  <!-- <div style="float:right">
        <button (click)="back()" class="btn btn-outline-danger" style="width: 15rem;">Ver más</button>
    </div> -->
  <div id="cupons" class="row justify-content-center">
    <div class="col-12 mt-5">
      <div style="text-align:center">
        <p class="tittle">Detalle de cupón</p>
      </div>
    </div>
    <div class="col-md-4 col-10 p-0">
      <div class="box-img-coupon">
        <img [src]="coupon.img">
      </div>
    </div>
    <div class="col-md-6 col-11 lateralCardCoupon">
      <div class="card-coupon">
        <div class="ribbon ribbon-top-right"><span>vigencia: {{coupon.vigency | date:'dd-MM-yyy'}}</span></div>
        <p style="font-size: 16pt; color: #706ed9;">{{company.name}}</p>
        <p style="font-size: 12pt; color: #3cd9ac;" *ngIf="metadata.limit - metadata.download > 0">{{(metadata.limit - metadata.download <= 0 ) ? 0 : metadata.limit - metadata.download  }} Cupones Disponibles</p>
        <p style="font-size: 12pt; color: #3cd9ac;" *ngIf="metadata.limit - metadata.download <= 0"> Cupones agotados.</p>
        <p class="mt-4 text-coupon-description">{{coupon.promotion_description}}</p>
        <ul>
          <li *ngIf="coupon.email != ''"><span class="material-icons">email</span> {{coupon.email}}</li>
          <!-- <li *ngIf="coupon.phone != ''"><span class="material-icons">phone</span> {{coupon.phone}}</li> -->
        </ul>

      </div>
      <div style="text-align: right; padding-top: 3%; padding-bottom: 3%;">
        <a class="btn btn-success" style="width: 15rem;" (click)="obtenerCupon()" *ngIf="metadata.limit - metadata.download > 0">Descargar cupón</a>
        <a class="btn btn-disabled" style="width: 15rem;" *ngIf="metadata.limit - metadata.download <= 0">Agotado</a>
        <small style="display: block;color: #8363e2;">*Para poder descargar el cupón debes loquearte.</small>
      </div>
    </div>
    <div class="col-11 col-md-10 box-reglas mt-5">
      <p style="font-size: 13pt; color: #8362e2;">Reglas de Cupon</p>
      <p class="mt-4 text-coupon-description">{{coupon.terms}}</p>
    </div>
    <div class="col-12 col-md-10 mt-5">
      <div class="row justify-content-center" style="padding: 0px;">
        <div class="col-6 col-md-3" *ngIf="company.logotipo != ''" style="display: flex; align-items: center; justify-content: flex-end; padding-right:0px; cursor:pointer;" (click)="sendToCoupones()">
          <div class="box-img">
            <img [src]="company.logotipo" class="img-company" alt="logo" style="width:100%;">
          </div>
        </div>
        <div class="col-11 col-md-9 lateralCardCompany">
          <div class="card-body text-center">
            <p style="font-size: 18pt; color: #706ed9;">{{company.name}}</p>
            <ul>
              <li *ngIf="company.email != ''"><span class="material-icons">email</span> {{company.email}}</li>
              <li *ngIf="company.address != ''"><span class="material-icons">place</span> {{company.address}}</li>
              <li *ngIf="company.phone != ''"><span class="material-icons">phone</span> {{company.phone}}</li>
            </ul>
            <a *ngIf="company.facebook" [href]="company.facebook" target="_blank"><img src="/assets/images/facebook.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
            <a *ngIf="company.twitter" [href]="company.twitter" target="_blank"><img src="/assets/images/twitter.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
            <a *ngIf="company.website" href="https://{{company.website}}" target="_blank"><img src="/assets/images/sitioweb.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
            <a *ngIf="company.linkedin" [href]="company.linkedin" target="_blank"><img src="/assets/images/linkedin.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
            <a *ngIf="company.instagram" [href]="company.instagram" target="_blank"><img src="/assets/images/instagram.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
            <button mat-button (click)="openModalAddress();" class="btnChafa">ver sucursales</button>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>
<app-footer></app-footer>
