import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuperuserCouponsUpdateComponent } from './superuser-coupons-update/superuser-coupons-update.component';
import * as Rx from 'rxjs/Rx'

@Component({
  selector: 'app-superuser-coupons',
  templateUrl: './superuser-coupons.component.html',
  styleUrls: ['./superuser-coupons.component.css']
})
export class SuperuserCouponsComponent implements OnInit {

  couponMin = {
    max: 10,
    page: 1,
    status:true,
    company:{
      id: ''
    },
    filter:''
  };
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;

  company = {
    id:''
  }

  companyMin = {
    max: 500,
    page: 1,
    status:true
  }
  companylist = [];
  companyListToSearch = [];
  metadata = {
    companySearch:'',
    searchBoxInput: new Rx.Subject<string>(),
  }
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar
  ) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.searching();
      }else{
        this.couponMin.filter = '';
        val = 'false'
        if(this.metadata.companySearch != ''){
          this.getList(this.couponMin.company.id);
        }else{
          this.viewAllCupons();
        }
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  ngOnInit(): void {
    this.getCompany();
    this.viewAllCupons();
  }
  /**función para obtener el listado de cupones
  */
   getList(id){
     // console.log('ev',event);
     this.loadding.show(true,"Espere un momento...");
     this.couponMin.company.id = id;
     this.session.postRequest('coupon:findAllByCompany', this.couponMin).subscribe((data: any) => {
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loadding.hide();
    }, error => {
      console.log('error:coupon:list', error);
      this.loadding.hide();
    });
  }

  getCompany(){
    this.session.postRequest('company:findAllByStatus', this.companyMin).subscribe((data: any) => {
      this.companylist = data.object.instanceList;
      this.companyListToSearch = data.object.instanceList;
    }, error => {
      console.log('error:company:finfAllByStatus', error);
    });
  }

  search(){
    // console.log(this.metadata.companySearch);
    if(this.metadata.companySearch != ''){
      this.companyListToSearch = this.companylist.filter((item)=>{
        let uppper = item.name.toUpperCase();
        let searchUpper = this.metadata.companySearch.toUpperCase();
        return uppper.indexOf(searchUpper) >= 0;
      })
    }else{
      this.companyListToSearch = this.companylist;
    }

  }

  companySelected(ev){
    // console.log(ev);
    this.couponMin.filter = '';
    this.getList(ev.option.id);
  }

  /** funcionalidad para crear un cupon
  */
  update(uuid:string){
    const dialogRef = this.dialog.open(SuperuserCouponsUpdateComponent, {
      width: '70%',
      height: '95%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.loadding.show(true,"Espere un momento...");
          this.session.postRequest('coupon:findAllByCompany', this.couponMin).subscribe((data: any) => {
           this.list = data.object.instanceList;
           this.paginator.total = data.object.total;
           this.loadding.hide();
         }, error => {
           console.log('error:coupon:list', error);
           this.loadding.hide();
         });
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  viewAllCupons(){
    this.metadata.companySearch = '';
    this.companyListToSearch = this.companylist;
    this.couponMin.company.id = '';
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest('coupon:findAllGlobal', this.couponMin).subscribe((data: any) => {
     this.list = data.object.instanceList;
     this.paginator.total = data.object.total;
     this.loadding.hide();
   }, error => {
     console.log('error:coupon:findAllGlobal', error);
     this.loadding.hide();
   });
  }

  delete(object:any){
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("coupon:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:coupon:delete",error);
      this.loadding.hide();
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.couponMin.max = event.pageSize;
    this.couponMin.page = event.pageIndex + 1;
    if(this.metadata.companySearch != ''){
      this.getList(this.couponMin.company.id);
    }else{
      this.viewAllCupons();
    }
   //  this.loadding.show(true,"Espere un momento...");
   //  this.session.postRequest('coupon:findAllByCompany', this.couponMin).subscribe((data: any) => {
   //   this.list = data.object.instanceList;
   //   this.paginator.total = data.object.total;
   //   this.loadding.hide();
   // }, error => {
   //   console.log('error:coupon:list', error);
   //   this.loadding.hide();
   // });
  }

  searching(){
    this.session.postRequest("coupon:searchingGlobal",this.couponMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
    },error=>{
      console.log("error:company:searching",error);
    })
  }
  searchInput(){
    this.metadata.searchBoxInput.next(this.couponMin.filter);
  }
}
