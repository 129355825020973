<app-menu></app-menu>
<div class="row justify-content-center downMenu">

  <div class="col-12 col-md-10 mt-5">
    <div class="row justify-content-center" style="padding: 0px;">
      <div class="col-6 col-md-3" *ngIf="company.logotipo != ''" style="display: flex; align-items: center; justify-content: flex-end; padding-right:0px;">
        <div class="box-img">
          <img [src]="company.logotipo" class="img-company" alt="logo" style="width:100%;">
        </div>
      </div>
      <div class="col-11 col-md-9 lateralCardCompany">
        <div class="card-body text-center">
          <p style="font-size: 18pt; color: #706ed9;">{{company.name}}</p>
          <ul>
            <li *ngIf="company.email != ''"><span class="material-icons">email</span> {{company.email}}</li>
            <li *ngIf="company.address != ''"><span class="material-icons">place</span> {{company.address}}</li>
            <li *ngIf="company.phone != ''"><span class="material-icons">phone</span> {{company.phone}}</li>
          </ul>
          <a *ngIf="company.facebook" [href]="company.facebook" target="_blank"><img src="/assets/images/facebook.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
          <a *ngIf="company.twitter" [href]="company.twitter" target="_blank"><img src="/assets/images/twitter.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
          <a *ngIf="company.website" href="https://{{company.website}}" target="_blank"><img src="/assets/images/sitioweb.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
          <a *ngIf="company.linkedin" [href]="company.linkedin" target="_blank"><img src="/assets/images/linkedin.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
          <a *ngIf="company.instagram" [href]="company.instagram" target="_blank"><img src="/assets/images/instagram.png" style="width: 1.8rem; margin-right:3px;" alt=""></a>
          <button mat-button (click)="openModalAddress();" class="btnChafa">ver sucursales</button>
          <br>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="container mt-1">
  <div style="text-align:center">
      <p class="tittle mt-3">Cupones</p>
  </div>
  <div class="row justify-content-center">
    <div class="col-9 col-md-3 text-center mb-3" *ngFor="let object of list; let i = index;">
      <mat-card class="p-0 mt-1 product-list" [routerLink]="['/coupons/details', object.id]" style="height: 21rem !important;">
        <!-- <div class="vigencyCintillo">
          Vigencia: {{object.vigency | date:'dd-MM-yyy'}}
        </div> -->
        <div class="ribbonCupon ribbon-top-right"><span>vigencia: {{object.vigency | date:'dd-MM-yyy'}}</span></div>

        <div class="frame">
          <img [src]="object.img">
        </div>
        <!-- <div class="btn-product">
          <button mat-raised-button color="accent" class="add-shopping text-uppercase">Ver detalle</button>
        </div> -->
        <div class="card-body">
          <p class="mb-1">{{object.company.name}}</p>
          <small class="text-uppercase text-secondary-min">{{object.category.name}}</small>
          <!-- <a [routerLink]="['/coupons/details', object.id]" class="btn btn-outline-primary btn-block">Ver detalles</a> -->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="sinDatos" *ngIf="list.length == 0">
    No tiene cupones disponibles.
  </div>
</div>
<app-footer></app-footer>
