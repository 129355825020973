import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx'


@Component({
  selector: 'app-superuser-profile',
  templateUrl: './superuser-profile.component.html',
  styleUrls: ['./superuser-profile.component.css']
})
export class SuperuserProfileComponent implements OnInit {
  profileMin = {
    max: 10,
    page: 1,
    status:true,
    filter:''
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
  }
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
      this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if(val != ''){
          this.searching();
        }else{
          this.profileMin.filter = '';
          val = 'false'
          this.getList();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
          console.log('error logged: ', error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
        });
     }

  ngOnInit(): void {
    this.getList();
  }

  /**función para obtener el listado de categorias
  */
  getList(){
    this.session.postRequest("profile:findAll",this.profileMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:profile:findAll",error);
    })
  }

  searching(){
    this.session.postRequest("profile:searching",this.profileMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
    },error=>{
      console.log("error:profile:searching",error);
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.profileMin.max = event.pageSize;
    this.profileMin.page = event.pageIndex + 1;
    this.getList();
  }

  search(){
    this.metadata.searchBoxInput.next(this.profileMin.filter);
  }

}
