<app-company-toolbar></app-company-toolbar>

<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Descargas</h2>
      </div>
      <mat-form-field class="col-12 col-md-6 offset-md-6 " appearance="fill">
        <mat-label>Buscador</mat-label>
        <input matInput placeholder="Buscador" name="search" [(ngModel)]="profileCouponMin.filter" (ngModelChange)="search()" >
      </mat-form-field>
    </div>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Usuario</th>
          <th>Email</th>
          <th>Promoción</th>
          <th>Estatus</th>
          <th>Acciones</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of list; let i = index;">
          <td>{{object.profile.name}}</td>
          <td>{{object.profile.email}}</td>
          <td>{{object.coupon.promotion_description}}</td>
          <td>
            <span class="usedCssNotUsed" *ngIf="!object.used">No utilizado</span>
            <span class="usedCssUsed" *ngIf="object.used">Utilizado</span>

          </td>
          <td>
            <div class="table-actions">
              <button mat-icon-button color="accent"  style="font-size: smaller;" matTooltip="Editar" (click)="updateChange(object);" ><mat-icon>published_with_changes</mat-icon></button>
            </div>

          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
