<button mat-icon-button (click)="close(false)" color="accent" style="float: right;">
        <mat-icon>clear</mat-icon>
</button>
<div class="row title-modal">
    <div class="col-1">

    </div>
    <div class="col-6">
      <h3>Nueva sucursal</h3>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-11">
      <div class="row mt-2">
        <div class="col-12">
          <p>Ingresa los datos requeridos del formulario.</p>
        </div>


        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Nombre.</mat-label>
          <input matInput placeholder="Nombre" name="name" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-12">
          <mat-label>Dirección.</mat-label>
          <textarea matInput placeholder="Dirección" name="address" [(ngModel)]="object.address" ></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-md-12">
          <mat-label>Insertar un link de mapa. (Google Maps)</mat-label>
          <textarea matInput placeholder="Insertar un mapa. (Google Maps)" name="address" [(ngModel)]="object.iframe" ></textarea>
          <mat-hint>
            Para obtener el map dirigete a Google Maps, selecciona la ubicación -> Compartir -> Enviar un enlace.
          </mat-hint>
        </mat-form-field>

      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-4">
          <button mat-flat-button color="warn" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send();">Guardar</button>
        </div>
      </div>
    </div>
  </div>
