<app-menu></app-menu>
<div class="card tam downMenu">
    <div class="container mt-4 rounded">
      <div class="row ">
        <div class="col bgimg d-none d-lg-block col-md-5 col-lg-5 col-xl-6">

        </div>
        <div class="col rounded-end">
          <div class="text-end">
            <h2 class="fw-bold text-center" style="padding: 20px;">Registro</h2>

            <form action="#" [formGroup]="contacto" (ngSubmit)="onSubmit()">

                <div class="mb-2 form-group">
                    <label class="form-label">Nombre</label>
                    <input type="text" class="form-control" formControlName="nombre" [ngClass]="{'is-invalid':submitted && f.nombre.errors}" [(ngModel)]="object.name">
                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                      <div *ngIf="f.nombre.errors.required">Campo requerido</div>
                  </div>
                  </div>

                  <div class="mb-2 form-group">
                    <label for="text" class="form-label">Apellido</label>
                    <input type="text" class="form-control" formControlName="apellido" [ngClass]="{'is-invalid':submitted && f.apellido.errors}" [(ngModel)]="object.lastname">
                    <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                      <div *ngIf="f.apellido.errors.required">Campo requerido</div>
                  </div>
                  </div>
              <div class="mb-2 form-group">
                <label for="email" class="form-label">Correo electrónico</label>
                <input type="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid':submitted && f.email.errors}" [(ngModel)]="object.email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Campo requerido</div>
              </div>
              </div>

              <div class="mb-2 form-group">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" formControlName="password" [ngClass]="{'is-invalid':submitted && f.password.errors}" [(ngModel)]="object.password">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Campo requerido</div>
              </div>
              </div>

              <div class="mb-2 form-group">
                <label for="password" class="form-label">Confirmar</label>
                <input type="password" class="form-control" formControlName="confirpassword" [ngClass]="{'is-invalid':submitted && f.confirpassword}" [(ngModel)]="object.password_confirmation">
                <div *ngIf="submitted && f.confirpassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirpassword.errors.required">Campo requerido</div>
                </div>
                <div *ngIf="object.password != '' && object.password_confirmation != ''">
                  <div style="color: red;" *ngIf="object.password != object.password_confirmation">La contraseña no conicide</div>
                </div>
              </div>



              <div class="row">
                <div class="col mt-5 text-center">
                    <button type="submit" class="btn btn-primary w-50" (click)="send(object);">Registrar</button>
                </div>
              </div>



            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    <app-footer></app-footer>
