import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-company-coupons-update',
  templateUrl: './company-coupons-update.component.html',
  styleUrls: ['./company-coupons-update.component.css']
})
export class CompanyCouponsUpdateComponent implements OnInit {


    @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
    complexForm: FormGroup;
    companyModel = {
      id:'',
      vigency:'',
      promotion_description:'',
      terms:'',
      img:'',
      email:'',
      phone:'',
      limit:'',
      category:{
        id:''
      },
      company:{
        id:''
      }
    };
    metadata = {
      vigency:'',
      img:{
        file: '',
        type: '',
        name: '',
      },
      uploadted:false,
      showUpdate:false
    }

    object = JSON.parse(JSON.stringify(this.companyModel));

    imgeModel = {
      img:{
        name:'',
        type:'',
        file:''
      },
      uploadted:false
    }
    imgesList:Array<any> = [];
    categoryMin = {
      max: 100,
      page: 1,
      status:true
    }
    categoryList = [];
    company = {
      id:''
    }


    constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompanyCouponsUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.complexForm = fb.group({
        'vigency': [null, Validators.compose([Validators.required])],
        'promotion_description': [null, Validators.compose([Validators.required])],
        'terms': [null, Validators.compose([Validators.required])],
        'email': [null, Validators.compose([Validators.required])],
        'phone': [null, Validators.compose([Validators.required])],
      });
    }

    async ngOnInit() {
      this.getCategories();
      await this.getCompany();
      if(this.data != 'new') {
        this.loadingService.wait();
         // cargamos los datos de la BD
        this.session.postRequest('coupon:get',{id:this.data}).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           this.metadata.vigency = moment(this.object.vigency).toISOString();
           this.metadata.img.name = this.object.img.substring(this.object.img.lastIndexOf("/")+1, this.object.img.length);
           this.metadata.uploadted = true;
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           console.log('Error: coupon:get',error)
         })
      }
    }

    /**función para obtener el listado de categorias
    */
    getCategories(){
      this.session.postRequest("category:findAllByStatus",this.categoryMin).subscribe((data:any)=>{
        this.categoryList = data.object.instanceList;
      },error=>{
        console.log("error:category:findAllByStatus",error);
      })
    }

    /**
     * funcion para guardar
     */
    send(object){
      this.loadingService.show(true,'Guardando registro...');
      object.company.id = this.company.id;
      object.vigency = moment(this.metadata.vigency).format('YYYY-MM-DD');
      this.session.postRequest("coupon:update",object).subscribe(
        async(data:any) => {
          this.snackBar.open('El registro se guardo correctamente', '', {
            duration: 8000
          });
          object.id = data.object.id;

          this.loadingService.hide();
          this.close(true);
        },
        error => {
          console.log("Error: coupon:update",error);
          this.loadingService.hide();
        }
      );
    }

    /**función para cargar una imagen
    */
    loadImg(){
      const fileUpload = this.fileGet.nativeElement;
      fileUpload.onchange = () => {
       this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
       this.metadata.img.type = fileUpload.files[0].type;
       let file = fileUpload.files[0];
       // console.log("FILE",fileUpload.files[0]);
       this.metadata.img.file = file;
       // console.log("FILE",this.metadata.img);
      };
      fileUpload.click();
    }

    /** función para subir una imagen al servidor
    */
    async uploadImg(){
      try{
        this.loadingService.show(true,"Espere un momento...")
        const formData = new FormData();
        formData.append('file', this.metadata.img.file, this.metadata.img.name);
        this.session.uploadFile("coupon:upload",formData).subscribe(async(data:any)=>{
          this.object.img = "https://www.cuponeravirtual.com.mx/public/coupon/"+data.object.name;
          this.metadata.uploadted = true;
          this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
          this.loadingService.hide();
        },error=>{
          console.log("coupon:upload",error);
          this.loadingService.hide();
        })
      }catch(e){
        console.log(e);
        this.loadingService.hide();
      }

    }

    /**función para quitar una iimagen
    */
    deleteImg(){
      this.metadata.img.file = '';
      this.metadata.img.name = '';
      this.metadata.uploadted = false;
    }

    async getCompany(){
      return new Promise((resolve,reject)=>{
        let user = this.session.getSession().user;
        console.log(":::::::::",user);
        this.session.postRequest("user:findByUser",{user:user}).subscribe((data:any)=>{
          this.company = data.object.assignment;
          resolve(true);
        },error=>{
          console.log("error:company:list",error);
          reject(error);
        })
      });
    }

      close(status){
        let object = {
          transaction:'',
          code:'',
          object:{}
        };
        if(status){
          object.transaction = 'ok';
          object.object = this.object;
        }else{
          object.transaction = 'bad';
          object.code = 'coupon:001';
        }
        this.dialogRef.close(object);
      }

      viewNext(){
        this.metadata.showUpdate = true;
      }
      viewAfther(){
        this.metadata.showUpdate = false;
      }

}
