import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuperuserCompanyUpdateComponent } from './superuser-company-update/superuser-company-update.component';
import { SuperuserCompanyUserComponent } from './superuser-company-user/superuser-company-user.component';;
import { SuperuserBranchComponent } from './superuser-branch/superuser-branch.component';
import { SuperuserCouponsViewComponent } from './superuser-coupons-view/superuser-coupons-view.component';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx'

@Component({
  selector: 'app-superuser-company',
  templateUrl: './superuser-company.component.html',
  styleUrls: ['./superuser-company.component.css']
})
export class SuperuserCompanyComponent implements OnInit {
  companyMin = {
    max: 10,
    page: 1,
    status:true,
    filter:''
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;

  packageMin = {
    max: 100,
    page: 1,
  }
  packageList:any = [];

  packageCompanyModel = {
    id:'',
    name:'',
    description:'',
    quantity:0,
    used:true,
    vigency:'',
    free:false,
    annual:false,
    price:'',
    company:{
      id:''
    },
    package:{
      id:''
    },
    active:true
  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
  }

  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.searching();
      }else{
        this.companyMin.filter = '';
        val = 'false'
        this.getList();
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  ngOnInit(): void {
    this.getPackageList();

    this.getList();
  }

  /**función para obtener el listado de categorias
  */
  getList(){
    this.session.postRequest("company:findAllByStatus",this.companyMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      for(let i = 0; i < this.list.length; i++){
        this.getCupons(this.list[i]);
        this.getPackages(this.list[i]);
      }
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:company:list",error);
    })
  }

  searching(){
    this.session.postRequest("company:searching",this.companyMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      for(let i = 0; i < this.list.length; i++){
        this.getCupons(this.list[i]);
        this.getPackages(this.list[i]);
      }
    },error=>{
      console.log("error:company:searching",error);
    })
  }

  /**funcionalidad para obtener los paquetes de una empresa
  */
  getPackages(object){
    this.session.postRequest("packageCompany:findAllByCompanyAndActive",{company:{id:object.id},active:true}).subscribe((data:any)=>{
      object.packages = [];
      object.packages = data.object.instanceList;
      if(object.packages.length == 0){
        object.packages.push( JSON.parse(JSON.stringify(this.packageCompanyModel)));
      }
    },error=>{
      console.log("error:packageCompany:findAllByCompanyAndActive",error);
    })
  }

  updatePackage(object,company){
    console.log(object);
    for(let item of this.packageList){
      if(object.package.id ==  item.id){
        object.company.id = company.id;
        object.active = true;
        object.price = item.price;
        object.annual = item.annual;
        object.free = item.free;
        object.vigency = moment().format("YYYY-MM-DD HH:mm");
        object.used = true;
        object.quantity = item.quantity;
        object.description = item.description;
        object.name = item.name;
        this.saveCompanyPackege(object);
      }
    }
    if(object.package.id == ''){
      if(object.id != ''){
        this.deleteCompanyPackege(object);
      }
    }
  }
  deleteCompanyPackege(object){
    this.session.postRequest("packageCompany:delete",object).subscribe((data:any)=>{
      object  = JSON.parse(JSON.stringify(this.packageCompanyModel))
    },error=>{
      console.log("error:packageCompany:delete",error);
    })
  }

  saveCompanyPackege(object){
    this.session.postRequest("packageCompany:update",object).subscribe((data:any)=>{
      object.id = data.object.id;
    },error=>{
      console.log("error:packageCompany:update",error);
    })
  }


  /** funcionalidad para crear una categoria
  */
  update(uuid:string){
    const dialogRef = this.dialog.open( SuperuserCompanyUpdateComponent, {
      width: '70%',
      height: '95%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  delete(object:any){
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("company:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:company:delete",error);
      this.loadding.hide();
    })
  }

  /** Funcionalidad para crear el usuario de la compañia
  */
  createUser(uuid){
    const dialogRef = this.dialog.open(SuperuserCompanyUserComponent, {
      width: '70%',
      height: '50%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          // this.getList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }
  getBranches(id){
    const dialogRef = this.dialog.open(SuperuserBranchComponent, {
      width: '90%',
      height: '90%',
      data: id,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**funcionalidad para obtener los cupones descargados de una compañia
  */
  getCupons(object){
    let couponMin = {
      max: 1000,
      page: 1,
      status:true,
      company:{
        id: object.id
      }
    };
    object.coupons = {
      downloaded:0,
      list:[]
    }
    this.session.postRequest('coupon:findAllByCompany', couponMin).subscribe((data: any) => {
     object.coupons.list = data.object.instanceList;
     for(let i = 0; i < object.coupons.list.length; i++){
        this.downloadedCupons(object.coupons.list[i],object.coupons);
     }
   }, error => {
     console.log('error:coupon:list', error);
     this.loadding.hide();
   });
  }

  /**funcionalidad para descargar cupones
  */
  downloadedCupons(object,coupons){
    this.session.postRequest("profileCoupon:findAllByCouponTotal",{ max: 100,page: 1,coupon:{id:object.id}}).subscribe((data:any)=>{
      object.downloaded = data.object.total
      coupons.downloaded = coupons.downloaded + data.object.total;
    },error=>{
      console.log("error:company:list",error);
    })
  }


  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.companyMin.max = event.pageSize;
    this.companyMin.page = event.pageIndex + 1;
    this.getList();
  }

  viewCupones(object){
    const dialogRef = this.dialog.open(SuperuserCouponsViewComponent, {
      width: '90%',
      height: '90%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**función para obtener el listado de categorias
  */
  getPackageList(){
    this.session.postRequest("package:findAll",this.packageMin).subscribe((data:any)=>{
      this.packageList = data.object.instanceList;
    },error=>{
      console.log("error:package:findAll",error);
    })
  }

  search(){
    this.metadata.searchBoxInput.next(this.companyMin.filter);
  }


}
