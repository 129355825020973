<div class="row justify-content-center">
  <div class="col-12">
    <button mat-icon-button class="naturaColor"  style="font-size: smaller;float: right;" matTooltip="Sucursales" (click)="close(true);" ><mat-icon>clear</mat-icon></button>
  </div>
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Personas que descargaron</h2>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <p><strong>Cupon:</strong>{{' '+data.promotion_description}}</p>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Telefono</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of list;">
          <td>
            {{object.profile.name + ' ' + object.profile.lastname}}
          </td>
          <td>
            {{object.profile.email}}
          </td>
          <td>
            {{object.profile.phone}}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
