import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { DownloadedCouponsChangeComponent } from './downloaded-coupons-change/downloaded-coupons-change.component'
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-downloaded-coupons',
  templateUrl: './downloaded-coupons.component.html',
  styleUrls: ['./downloaded-coupons.component.css']
})
export class DownloadedCouponsComponent implements OnInit {

  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  company = {
    id:''
  }

  profileCouponMin = {
    max: 10,
    page: 1,
    status:true,
    company:{
      id:''
    },
    filter:''
  }
  list = [];


    metadata = {
      search: new Rx.Subject<string>(),
    }
  constructor(private session: SessionService,
    public loadding: LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
      this.metadata.search.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val)
          if(val == ''){
            this.list = [];
            this.profileCouponMin.filter = '';
          }else{
            this.list = [];
          }
          this.getList();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
     }

    async ngOnInit() {
      await this.getCompany();
      this.getList();
      //console.log(list);

    }

    async getCompany(){
      return new Promise((resolve,reject)=>{
        let user = this.session.getSession().user;
        this.session.postRequest("user:findByUser",{user:user}).subscribe((data:any)=>{
          this.company = data.object.assignment;
          resolve(true);
        },error=>{
          console.log("error:company:list",error);
          reject(error);
        })
      });
    }

    /**función para obtener el listado de cupones descargados
    */

    getList(){
      return new Promise((resolve,reject)=>{
        if(this.profileCouponMin.filter == ''){
          this.profileCouponMin.company.id = this.company.id;
          this.session.postRequest("profileCoupon:findAllByCompany",this.profileCouponMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
          },error=>{
            console.log("error:profileCoupon:findAllByCompany",error);
            reject(error);
          })
        }else{
          this.profileCouponMin.company.id = this.company.id;
          this.session.postRequest("profileCoupon:searching",this.profileCouponMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
          },error=>{
            console.log("error:profileCoupon:searching",error);
          })
        }

      });
    }

    updateChange(object){
      const dialogRef = this.dialog.open(DownloadedCouponsChangeComponent, {
        width: '36rem',
        data: object,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            this.getList();
          }else{
            // El modal se cerro sin objeto
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    search(){
      this.profileCouponMin.page = 1;
      if(this.profileCouponMin.filter == ''){
        this.getList();
      }else{
        this.metadata.search.next(this.profileCouponMin.filter);
      }
    }


    // funcion para obtener los datos del paginado.
    onPaginateChange(event:any){
      this.profileCouponMin.max = event.pageSize;
      this.profileCouponMin.page = event.pageIndex + 1;
      this.getList();
    }
}
