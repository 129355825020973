<app-superuser-toolbar></app-superuser-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Solicitudes</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva empresa</button>
        </div>
      </div>
      <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab($event);">
        <mat-tab label="{{object}}" *ngFor="let object of statusList"></mat-tab>
      </mat-tab-group>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Nombre</th>
            <th>Giro</th>
            <th>Persona de contacto</th>
            <th>Datos de contacto</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>
              {{object.name}}
            </td>
            <td>
              {{object.giro}}
            </td>
            <td>
              {{object.name_contact}}
            </td>
            <td>
              <ul>
                <li>Tel. {{object.phone_contact}}</li>
                <li>Email. {{object.email_contact}}</li>
              </ul>
            </td>
            <td>
              <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Ver solicitud" (click)="update(object.id);" ><mat-icon>wysiwyg</mat-icon></button>

            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
