import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-superuser-company-update',
  templateUrl: './superuser-company-update.component.html',
  styleUrls: ['./superuser-company-update.component.css']
})
export class SuperuserCompanyUpdateComponent implements OnInit {

  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;
  companyModel = {
    id:'',
    name_contact:'',
    email_contact:'',
    phone_contact:'',
    social_reason:'',
    name:'',
    slogan:'',
    giro:'',
    area:'',
    abstract:'',
    whastapp:'',
    address:'',
    website:'',
    phone:'',
    email:'',
    facebook:'',
    instagram:'',
    twitter:'',
    linkedin:'',
    logotipo:'',
    status:true
  }
  metadata = {
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }

  object = JSON.parse(JSON.stringify(this.companyModel));

  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }
  imgesList:Array<any> = [];

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<SuperuserCompanyUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // 'name_contact': [null, Validators.compose([Validators.required])],
      // 'email_contact': [null, Validators.compose([Validators.required])],
      // 'phone_contact': [null, Validators.compose([Validators.required])],
      // 'social_reason': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      // 'slogan': [null, Validators.compose([Validators.required])],
      // 'giro': [null, Validators.compose([Validators.required])],
      // 'area': [null, Validators.compose([Validators.required])],
      // 'abstract': [null, Validators.compose([Validators.required])],
      // 'whastapp': [null, Validators.compose([Validators.required])],
      // 'address': [null, Validators.compose([Validators.required])],
      // 'website': [null, Validators.compose([Validators.required])],
      // 'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('company:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.img.name = this.object.logotipo.substring(this.object.logotipo.lastIndexOf("/")+1, this.object.logotipo.length);
         this.metadata.uploadted = true;
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: company:get',error)
       })
    }
  }

  /**
   * funcion para guardar
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("company:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        object.id = data.object.id;

        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: company:update",error);
        this.loadingService.hide();
      }
    );
  }

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     // console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     // console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile("company:upload",formData).subscribe(async(data:any)=>{
        this.object.logotipo = "https://www.cuponeravirtual.com.mx/public/company/"+data.object.name;
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
      },error=>{
        console.log("company:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'company:001';
      }
      this.dialogRef.close(object);
    }
}
