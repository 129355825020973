import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../service/session/session.service';
import { LoadingService } from '../directive/loading/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

contacto;
submitted = false;

  registerModel = {
    id: '',
    name: '',
    lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    role: 'CLIENT'
  };
  perfileModel = {
    id:'',
    name:'',
    lastname:'',
    phone:'',
    email:'',
    img:'',
    user:{
      id:''
    }
  }


  object = JSON.parse(JSON.stringify(this.registerModel));



  constructor(private session: SessionService, private formbuilder: FormBuilder, private snackBar: MatSnackBar, private router: Router, public loading:LoadingService) {
    this.contacto = this.formbuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirpassword: ['', Validators.required],
  });
  }

  ngOnInit(): void {
  }
  get f() { return this.contacto.controls; }
  onSubmit(): void {
    this.submitted = true;

    if (this.contacto.invalid) {
        return;
    }
}
  send(object: any){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus('auth:signup', object).subscribe((data: any) => {
      this.perfileModel.user.id = data.object.id;
      this.perfileModel.name = this.object.name;
      this.perfileModel.email = this.object.email;
      this.perfileModel.lastname = this.object.lastname;
      this.perfileModel.phone = this.object.phone;
      this.session.postRequestAnonimus('profile:update', this.perfileModel).subscribe(( data: any) => {
        this.snackBar.open('Registro Exitoso', 'Aceptar', {duration: 5000});
        this.loading.hide();
        this.router.navigate(['/login']);
      }, error => {
        console.log(error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        this.loading.hide();
      });
    }, error => {
      console.log(error);
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loading.hide();
    });
  }

}
