<div class="row p-2 m-0" [ngClass]="{'menuFixed' : metadata.menuFixed}" #menu style="height: 12vh; background: white;">
  <div class="col-2 logo" style="height: 100%;" routerLink="/admin/dashboard">
    <img src="assets/images/cuponera.jpg" style="width: 100px; padding: 5px;">
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/superuser/packages">PAQUETES</a></li>
      <li class="horizontal"><a routerLink="/superuser/coupons">CUPONES</a></li>
      <li class="horizontal"><a routerLink="/superuser/company">EMPRESAS</a></li>
      <li class="horizontal"><a routerLink="/superuser/requestcompany">SOLICITUDES</a></li>
      <li class="horizontal"><a routerLink="/superuser/category">CATEGORIAS</a></li>
      <li class="horizontal"><a routerLink="/superuser/profile">USUARIOS</a></li>
      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
</div>
