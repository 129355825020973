<app-company-toolbar></app-company-toolbar>

  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h1 class="mb-2 morado">Estadísticas</h1>
          <p>Consulta informes detallados sobre el rendimiento de tus cupones.</p>
        </div>

      </div>
      <div class="row justify-content-center mb-3">
        <div class="col-12">
          <mat-card>
            <h3 class="morado col-12">Serie Temporal</h3>
            <div class="row justify-content-end">
              <p class="col-12">Seleccione el periodo de fecha a mostrar para ver los cupones descargados. </p>
              <mat-form-field color="accent" appearance="outline" class="col-6 col-md-4" style="display: flex; align-items: flex-end;">
                <mat-label>Inicio</mat-label>
                <input matInput [matDatepicker]="picker1" name="dateDelivery" [(ngModel)]="metadata.start" (ngModelChange)="changeDate();"(click)="picker1.open();" required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

              <mat-form-field color="accent" appearance="outline" class="col-6 col-md-4" style="display: flex; align-items: flex-end;">
                <mat-label>Final</mat-label>
                <input matInput [matDatepicker]="picker2" name="dateDeliver2y" [(ngModel)]="metadata.end" (ngModelChange)="changeDate();"(click)="picker2.open();" required>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>

              <div class="col-12">
                <div style="display: block;">
                  <canvas baseChart
                              [datasets]="lineChartData"
                              [labels]="lineChartLabels"
                              [options]="lineChartOptions"
                              [colors]="lineChartColors"
                              [legend]="lineChartLegend"
                              [chartType]="lineChartType"
                              [plugins]="lineChartPlugins">
                    </canvas>
                  </div>
              </div>
            </div>

          </mat-card>
        </div>
        <div class="col-12 mb-2 mt-3">
          <mat-card>
            <div class="row">
              <h3 class="morado col-12">Cupones descargados</h3>
              <div class="col-4">
                <div class="chart">
                   <canvas baseChart
                     [data]="pieChartData"
                     [labels]="pieChartLabels"
                     [chartType]="pieChartType"
                     [options]="pieChartOptions"
                     [plugins]="pieChartPlugins"
                     [colors]="pieChartColors"
                     [legend]="pieChartLegend">
                   </canvas>
                 </div>
              </div>
              <div class="col-8">
                <table class="table table-general">
                  <thead class="header-table">
                    <tr>
                      <th>Cupón</th>
                      <th>Descargados</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let object of pieChartByCoupons; let i = index;">
                      <td>{{object.name}}</td>
                      <td>{{object.download}}</td>
                      <td>{{object.download + object.noDownload}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-card>
        </div>
      </div>


    </div>
  </div>
