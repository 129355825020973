<div class="row">
  <div class="col-12">
    <p><strong>Cambie el estado del cupón si ya se ha utilizado.</strong></p>
    <p>Cliente:&nbsp;{{data.profile.name}}</p>
    <p>Email:&nbsp;{{data.profile.email}}</p>
    <p>Promoción:&nbsp;{{data.coupon.promotion_description}}</p>
    <p>
      Estatus:&nbsp;
      <span class="usedCssNotUsed1" *ngIf="!data.used">No utilizado</span>
      <span class="usedCssUsed1" *ngIf="data.used">Utilizado</span>
    </p>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11 mt-2">
    <button mat-flat-button color="accent" style="width:100%;" (click)="changeSatatus(true);" *ngIf="!data.used">Cupón utilizado</button>
  </div>
</div>
