<app-superuser-toolbar></app-superuser-toolbar>

<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Cupones</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Cupon</button>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Empresas</mat-label>
          <input type="text"
                 placeholder="selecciona / busca una empresa"
                 matInput
                 [matAutocomplete]="auto" [(ngModel)]="metadata.companySearch" (ngModelChange)="search();">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="companySelected($event);">
            <mat-option *ngFor="let option of companyListToSearch" [value]="option.name" id={{option.id}}>
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix style="color:#f58734; cursor:pointer;" (click)="viewAllCupons();" *ngIf="metadata.companySearch != ''">clear</mat-icon>

        </mat-form-field>
      </div>
      <mat-form-field class="col-6" style="text-aling: right; width: 100%;">
        <input matInput placeholder="Buscar" [(ngModel)]="couponMin.filter" (ngModelChange)="searchInput()" >
      </mat-form-field>
    </div>
    <div class="col-12">
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Vigencia</th>
            <th>Promoción</th>
            <th>Email</th>
            <th>Tel</th>
            <th>Limite</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>{{object.vigency}}</td>
            <td>{{object.promotion_description}}</td>
            <td>{{object.email}}</td>
            <td>{{object.phone}}</td>
            <td>{{object.limit}}</td>
            <td>
              <div class="table-actions">
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id);" ><mat-icon>edit</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Eliminar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
