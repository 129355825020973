import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-toolbar',
  templateUrl: './company-toolbar.component.html',
  styleUrls: ['./company-toolbar.component.css']
})
export class CompanyToolbarComponent implements OnInit {

  @ViewChild('menu',{static:false}) menu: ElementRef;

  metadata = {
    menuFixed:false,
    loggin:false,
  }

  constructor(private session:SessionService, public router:Router) { }

  ngOnInit(): void {
  }

  //Logica para hacer el menu fijo
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event:any) {
    if( event.target.scrollingElement.scrollTop > this.menu.nativeElement.offsetTop){
      this.metadata.menuFixed = true;
    }else{
      this.metadata.menuFixed = false;
    }
  }

  /**funcionalida para salir del sistema
  */
  logout(){
    this.session.logout().then(()=>{
      this.router.navigate(['/']);
    })
  }

}
