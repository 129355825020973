import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-superuser-company-user',
  templateUrl: './superuser-company-user.component.html',
  styleUrls: ['./superuser-company-user.component.css']
})
export class SuperuserCompanyUserComponent implements OnInit {
  complexForm: FormGroup;
  companyModel = {
    id:'',
    name_contact:'',
    email_contact:'',
    phone_contact:'',
    social_reason:'',
    name:'',
    slogan:'',
    giro:'',
    area:'',
    abstract:'',
    whastapp:'',
    address:'',
    website:'',
    phone:'',
    email:'',
    facebook:'',
    instagram:'',
    twitter:'',
    linkedin:'',
    logotipo:'',
    status:true
  }
  registerModel = {
    id: '',
    name: '',
    lastname: '.',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    role: 'COMPANY'
  };
  perfileModel = {
    id:'',
    name:'',
    lastname:'',
    phone:'',
    email:'',
    img:'',
    user:{
      id:''
    }
  };

  userAssignmentModel = {
    type:'',
    user:{
      id:''
    },
    assignment:{
      id:''
    }
  };

  profile = JSON.parse(JSON.stringify(this.perfileModel));
  object = JSON.parse(JSON.stringify(this.registerModel));
  company = JSON.parse(JSON.stringify(this.companyModel));
  assigmentList = [];

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<SuperuserCompanyUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required])],
      'password_confirmation': [null, Validators.compose([Validators.required])],
      'phone': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('company:get',{id:this.data}).subscribe((data:any)=>{
         this.company = JSON.parse(JSON.stringify(data.object));
         this.object.name = this.company.name_contact;
         this.object.phone = this.company.phone_contact;
         this.object.email = this.company.email_contact;
         this.getUserFromCompany();
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: company:get',error)
       })
    }
  }


  send(object: any){
    this.loadingService.show(true,"Espere un momento...");
    this.session.postRequestAnonimus('auth:signup', object).subscribe((data: any) => {
      this.object.id = data.object.id;
      this.profile.user.id = data.object.id;
      this.profile.name = this.object.name;
      this.profile.email = this.object.email;
      this.profile.lastname = this.object.lastname;
      this.profile.phone = this.object.phone;
      this.session.postRequestAnonimus('profile:update', this.profile).subscribe(( data: any) => {
        let assigment = JSON.parse(JSON.stringify(this.userAssignmentModel));
        assigment.type = "COMPANY";
        assigment.user.id =  this.object.id;
        assigment.assignment.id = this.company.id;
        this.session.postRequestAnonimus('user:assignment', assigment).subscribe(( data: any) => {
          this.snackBar.open('Usuario y contraseña creados exitosamenete.', 'Aceptar', {duration: 5000});
          this.loadingService.hide();
          this.close(true);
        }, error => {
          console.log(error);
          this.loadingService.hide();
        });
      }, error => {
        console.log(error);
        this.loadingService.hide();
      });
    }, error => {
      console.log(error);
      this.loadingService.hide();
    });
  }


  /**funcionalidad para obtener los usuarios de una empresa
  */
  getUserFromCompany(){
    this.session.postRequest('user:findAllUsersByCompany',{object_id:this.company.id}).subscribe((data:any)=>{
      this.assigmentList = data.instanceList;
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error: user:findAllUsersByCompany',error)
    })
  }

  delete(object){
    this.loadingService.show(true, "Espere un momento...");
    this.session.postRequest("user:deleteUser",object).subscribe((data:any)=>{
      for(let i = 0; i < this.assigmentList.length; i++){
        if(this.assigmentList[i].id == object.id){
          this.assigmentList.splice(i,1);
        }
      }
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'company:001';
    }
    this.dialogRef.close(object);
  }

}
