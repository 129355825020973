<app-superuser-toolbar></app-superuser-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Paquetes</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nuevo paquete</button>
        </div>
      </div>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Cupones</th>
            <th>Datos adicionales</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>
              {{object.name}}
            </td>
            <td>
              {{object.description}}
            </td>
            <td>
              {{object.quantity}} Cupones
            </td>
            <td>
              <p *ngIf="!object.free" class="p-0 m-0"> <strong style="color:#0acc94;">Precio: {{object.price}} x  <span *ngIf="object.annual">Año</span> <span *ngIf="!object.annual">Mes</span> </strong> </p>
              <p *ngIf="object.free" class="p-0 m-0"> <strong style="color:#0acc94;">Precio: Gratis </strong> </p>
              <!-- <p style="color: #9e9e9e; font-size: smaller;">Vigencia: {{object.vigency | date:'dd/MM/yyyy'}}</p> -->

            </td>

            <td>
              <div class="table-actions">
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id);" ><mat-icon>edit</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
