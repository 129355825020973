<app-superuser-toolbar></app-superuser-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Empresas</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva empresa</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">

        </div>
        <mat-form-field class="col-6" style="text-aling: right; width: 100%;">
          <input matInput placeholder="Buscar" [(ngModel)]="companyMin.filter" (ngModelChange)="search()" >
        </mat-form-field>
      </div>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Nombre</th>
            <th>Telefono</th>
            <th>Email</th>
            <th>Cupones Habilitados</th>
            <th>Cupones descargados</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>
              {{object.name}}
            </td>
            <td>
              {{object.phone}}
            </td>
            <td>
              {{object.email}}
            </td>
            <td>
              <div *ngFor="let packageC of object.packages; let j = index;">
                <mat-form-field appearance="fill">
                  <mat-select matNativeControl  name="cupone{{i}}{{j}}" [(ngModel)]="packageC.package.id" (selectionChange)="updatePackage(packageC,object);">
                    <mat-option value="" > - Sin Paquete - </mat-option>
                    <mat-option [value]="object.id" *ngFor="let object of packageList">{{object.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td style="text-align: right;">
              <ul style="list-style-type:none;">
                <li *ngFor="let object of object.coupons.list">
                  {{object.promotion_description}}
                  |  <button mat-icon-button class="accent"  style="font-size: smaller;" matTooltip="Ver quienes descargaron" (click)="viewCupones(object);" ><mat-icon>visibility</mat-icon></button>
                  <!-- | <span style="color:#0acc94;">{{object.downloaded}}</span> -->
                </li>
              </ul>
              <p>
                <strong style="color:#0acc94;">Descargas: {{object.coupons.downloaded}}</strong>
              </p>

            </td>

            <td>
              <div class="table-actions">
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id);" ><mat-icon>edit</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Sucursales" (click)="getBranches(object.id)" ><mat-icon>business</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Usuario" (click)="createUser(object.id);" ><mat-icon>account_circle</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
