import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-downloaded-coupons-change',
  templateUrl: './downloaded-coupons-change.component.html',
  styleUrls: ['./downloaded-coupons-change.component.css']
})
export class DownloadedCouponsChangeComponent implements OnInit {

  constructor(private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DownloadedCouponsChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
  }

  /**change status
  */

  changeSatatus(status){
    this.data.used = status;
    this.session.postRequest("profileCoupon:changeUsedStatus",this.data).subscribe((data:any)=>{
      this.close(true);
    },error=>{
      console.log(error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.data;
    }else{
      object.transaction = 'bad';
      object.code = 'changeUsedStatus:001';
    }
    this.dialogRef.close(object);
  }

}
