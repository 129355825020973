
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },
  {
    code: "profile:update",
    endpoint: "profile/update"
  },{
    code: "company:findAllByStatus",
    endpoint: "company/findAllByStatus"
  },{
    code: "company:get",
    endpoint: "company/get"
  },{
    code: "company:delete",
    endpoint: "company/delete"
  },{
    code: "company:update",
    endpoint: "company/update"
  },{
    code: "company:upload",
    endpoint: "company/upload"
  },{
    code: "user:assignment",
    endpoint: "user/assignment"
  },{
    code: "category:findAllByStatus",
    endpoint: "category/findAllByStatus"
  },{
    code: "category:update",
    endpoint: "category/update"
  },{
    code: "category:get",
    endpoint: "category/get"
  },{
    code: "category:delete",
    endpoint: "category/delete"
  },{
    code: "category:upload",
    endpoint: "category/upload"
  },{
    code: "user:findByUser",
    endpoint: "user/findByUser"
  },{
    code: "coupon:update",
    endpoint: "coupon/update"
  },{
    code: "coupon:upload",
    endpoint: "coupon/upload"
  },{
    code: "coupon:delete",
    endpoint: "coupon/delete"
  },{
    code: "coupon:findAllByCompany",
    endpoint: "coupon/findAllByCompany"
  },{
    code: "coupon:findAllByCategory",
    endpoint: "coupon/findAllByCategory"
  },{
    code: "coupon:findAllRamdom",
    endpoint: "coupon/findAllRamdom"
  },{
    code: "coupon:get",
    endpoint: "coupon/get"
  },{
    code: "profileCoupon:findByProfileAndCoupon",
    endpoint: "profileCoupon/findByProfileAndCoupon"
  },{
    code: "profileCoupon:findAllByCoupon",
    endpoint: "profileCoupon/findAllByCoupon"
  },{
    code: "profile:findByUser",
    endpoint: "profile/findByUser"
  },{
    code: "profileCoupon:update",
    endpoint: "profileCoupon/update"
  },{
    code: "profileCoupon:findAllByProfile",
    endpoint: "profileCoupon/findAllByProfile"
  },{
    code: "coupon:searching",
    endpoint: "coupon/searching"
  },{
    code: "company:searching",
    endpoint: "company/searching"
  },{
    code: "coupon:download",
    endpoint: "coupon/download"
  },{
    code: "profileCoupon:findAllByCoupon",
    endpoint: "profileCoupon/findAllByCoupon"
  },{
    code:'auth:loginWithGoogle',
    endpoint: 'auth/loginWithGoogle'
  },{
    code:'auth:loginWithFacebook',
    endpoint: 'auth/loginWithFacebook'
  },{
    code: "profileCoupon:changeUsedStatus",
    endpoint: "profileCoupon/changeUsedStatus"
  },{
    code: "profileCoupon:findAllByCompany",
    endpoint: "profileCoupon/findAllByCompany"
  },{
    code: "profileCoupon:searching",
    endpoint: "profileCoupon/searching"
  },{
    code: "profileCoupon:findAllByCouponTotal",
    endpoint: "profileCoupon/findAllByCouponTotal"
  },{
    code: "profileCoupon:findAllByUsedTotal",
    endpoint: "profileCoupon/findAllByUsedTotal"
  },{
    code: "coupon:findAllByCompanyPublic",
    endpoint: "coupon/findAllByCompanyPublic"
  },{
    code: "branch:findAllByCompany",
    endpoint: "branch/findAllByCompany"
  },{
    code: "branch:update",
    endpoint: "branch/update"
  },{
    code: "branch:get",
    endpoint: "branch/get"
  },{
    code: "branch:delete",
    endpoint: "branch/delete"
  },{
    code: "profileCoupon:sendEmailToCompany",
    endpoint: "profileCoupon/sendEmailToCompany"
  },{
    code: "package:update",
    endpoint: "package/update"
  },{
    code: "package:get",
    endpoint: "package/get"
  },{
    code: "package:delete",
    endpoint: "package/delete"
  },{
    code: "package:findAll",
    endpoint: "package/findAll"
  },{
    code: "profileCoupon:findAllByCouponTotalByPeriod",
    endpoint: "profileCoupon/findAllByCouponTotalByPeriod"
  },{
    code: "profileCoupon:findAllByUsedTotalByPeriod",
    endpoint: "profileCoupon/findAllByUsedTotalByPeriod"
  },{
    code: "requestcompany:findAllByStatus",
    endpoint: "requestcompany/findAllByStatus"
  },{
    code: "requestcompany:get",
    endpoint: "requestcompany/get"
  },{
    code: "requestcompany:delete",
    endpoint: "requestcompany/delete"
  },{
    code: "requestcompany:update",
    endpoint: "requestcompany/update"
  },{
    code: "requestcompany:upload",
    endpoint: "requestcompany/upload"
  },{
    code: "requestcompany:sendEmailNewCompany",
    endpoint: "requestcompany/sendEmailNewCompany"
  },{
    code: "packageCompany:findAllByCompanyAndActive",
    endpoint: "packageCompany/findAllByCompanyAndActive"
  },{
    code: "packageCompany:delete",
    endpoint: "packageCompany/delete"
  },{
    code: "packageCompany:update",
    endpoint: "packageCompany/update"
  },{
    code: "profile:findAll",
    endpoint: "profile/findAll"
  },{
    code: "profile:searching",
    endpoint: "profile/searching"
  },{
    code: "user:newUser",
    endpoint: "user/newUser"
  },{
    code: "user:findAllUsersByCompany",
    endpoint: "user/findAllUsersByCompany"
  },{
    code: "user:deleteUser",
    endpoint: "user/deleteUser"
  },{
    code: "coupon:findAllGlobal",
    endpoint: "coupon/findAllGlobal"
  },{
    code: "coupon:searchingGlobal",
    endpoint: "coupon/searchingGlobal"
  }
];

export default urls;
