import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-superuser-requestcompany-update',
  templateUrl: './superuser-requestcompany-update.component.html',
  styleUrls: ['./superuser-requestcompany-update.component.css']
})
export class SuperuserRequestcompanyUpdateComponent implements OnInit {

  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  requestcompanyModel = {
    id:'',
    name_contact:'',
    email_contact:'',
    phone_contact:'',
    social_reason:'',
    name:'',
    slogan:'',
    giro:'',
    area:'',
    abstract:'',
    whastapp:'',
    address:'',
    website:'',
    phone:'',
    email:'',
    facebook:'',
    instagram:'',
    twitter:'',
    linkedin:'',
    logotipo:'',
    status:true,
    statusCompany:'Nuevo'
  }
  metadata = {
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }

  object = JSON.parse(JSON.stringify(this.requestcompanyModel));

  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }
  imgesList:Array<any> = [];

  registerModel = {
    id: '',
    name: '',
    lastname: '.',
    email: '',
    password: 'cup0n3r4Virtual',
    password_confirmation: 'cup0n3r4Virtual',
    phone: '',
    role: 'COMPANY'
  };
  perfileModel = {
    id:'',
    name:'',
    lastname:'',
    phone:'',
    email:'',
    img:'',
    user:{
      id:''
    }
  };
  userAssignmentModel = {
    type:'',
    user:{
      id:''
    },
    assignment:{
      id:''
    }
  };
  register = JSON.parse(JSON.stringify(this.registerModel));
  profile = JSON.parse(JSON.stringify(this.perfileModel));



  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<SuperuserRequestcompanyUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('requestcompany:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.img.name = this.object.logotipo.substring(this.object.logotipo.lastIndexOf("/")+1, this.object.logotipo.length);
         this.metadata.uploadted = true;
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: requestcompany:get',error)
       })
    }
  }

  /**
   * funcion para guardar
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("requestcompany:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        object.id = data.object.id;

        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: requestcompany:update",error);
        this.loadingService.hide();
      }
    );
  }

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     // console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     // console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile("requestcompany:upload",formData).subscribe(async(data:any)=>{
        this.object.logotipo = "https://www.cuponeravirtual.com.mx/public/company/"+data.object.name;
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
      },error=>{
        console.log("company:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  /**
   * funcion para guardar
   */
  chageStatus(object,status){
    object.statusCompany = status;
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("requestcompany:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        object.id = data.object.id;
        if(status == 'Aceptada'){
          this.loadingService.show(true,'Creando empresa...');
          let objectCompany = JSON.parse(JSON.stringify(this.object));
          objectCompany.id = '';
          this.session.postRequest("company:update",objectCompany).subscribe(
            async(data:any) => {
              this.snackBar.open('El registro se guardo correctamente', '', {
                duration: 8000
              });
              this.createUser(data.object);
              this.snackBar.open("La solicitud fue aceptada, se ha creado la compañia. Vea el panel de empresas para crear el acceso.","",{duration:11000})
              this.loadingService.hide();
              this.close(true);
            },
            error => {
              console.log("Error: company:update",error);
              this.loadingService.hide();
            }
          );
        }else{
          this.snackBar.open("La solicitud fue rechazada.","",{duration:3000})
          this.loadingService.hide();
          this.close(true);
        }
      },
      error => {
        console.log("Error: requestcompany:update",error);
        this.loadingService.hide();
      }
    );
  }

  createUser(company){
    this.loadingService.show(true,"Espere un momento...");
    this.register.name = this.object.name_contact;
    this.register.phone = this.object.phone_contact;
    this.register.email = this.object.email_contact;
    this.session.postRequestAnonimus('auth:signup', this.register).subscribe((data: any) => {
      this.register.id = data.object.id;
      this.profile.user.id = data.object.id;
      this.profile.name = this.register.name;
      this.profile.email = this.register.email;
      this.profile.lastname = this.register.lastname;
      this.profile.phone = this.register.phone;
      this.session.postRequestAnonimus('profile:update', this.profile).subscribe(( data: any) => {
        let assigment = JSON.parse(JSON.stringify(this.userAssignmentModel));
        assigment.type = "COMPANY";
        assigment.user.id =  this.register.id;
        assigment.assignment.id = company.id;
        this.session.postRequestAnonimus('user:assignment', assigment).subscribe(( data: any) => {
          // this.snackBar.open('Usuario y contraseña creados exitosamenete.', 'Aceptar', {duration: 5000});
          this.session.postRequest('user:newUser', this.register).subscribe(( data: any) => {
            // this.snackBar.open('Usuario y contraseña creados exitosamenete.', 'Aceptar', {duration: 5000});
          }, error => {
            console.log(error);
            this.loadingService.hide();
          });
        }, error => {
          console.log(error);
          this.loadingService.hide();
        });
      }, error => {
        console.log(error);
        this.loadingService.hide();
      });
    }, error => {
      console.log(error);
      this.loadingService.hide();
    });
  }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'requestcompany:001';
      }
      this.dialogRef.close(object);
    }

}
