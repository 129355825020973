<app-menu></app-menu>
<div class="card conten text-center downMenu">
    <div class="card-body">
      <div class="row justify-content-center m-0" style="padding-top: 1rem; padding-bottom: 1rem;">
          <div class="col-md-8 col-11" style="padding: 1rem 0rem;">
              <p  class="tittle-search">Todos los cupones</p>
              <p  class="tittle-search mt-2 mt-md-5">de tus tiendas favoritas </p>
          </div>
      </div>
    </div>
</div>
<div class="container mt-1">
  <div style="text-align:center">
      <p class="tittle mt-3">Cupones</p>
  </div>
  <div class="row justify-content-center">
    <div class="col-9 col-md-3 text-center mb-3" *ngFor="let object of list; let i = index;">
      <mat-card class="p-0 mt-1 product-list" [routerLink]="['/coupons/details', object.id]" style="height: 21rem !important;">
        <!-- <div class="vigencyCintillo">
          Vigencia: {{object.vigency | date:'dd-MM-yyy'}}
        </div> -->
        <div class="ribbonCupon ribbon-top-right"><span>vigencia: {{object.vigency | date:'dd-MM-yyy'}}</span></div>

        <div class="frame">
          <img [src]="object.img">
        </div>
        <!-- <div class="btn-product">
          <button mat-raised-button color="accent" class="add-shopping text-uppercase">Ver detalle</button>
        </div> -->
        <div class="card-body">
          <p class="mb-1">{{object.company.name}}</p>
          <small class="text-uppercase text-secondary-min">{{object.category.name}}</small>
          <!-- <a [routerLink]="['/coupons/details', object.id]" class="btn btn-outline-primary btn-block">Ver detalles</a> -->
        </div>
      </mat-card>
    </div>
  </div>
  <div class="sinDatos" *ngIf="list.length == 0">
    No tiene cupones disponibles.
  </div>
</div>

<app-footer></app-footer>
