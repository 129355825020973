<div class="main-wrapper fixed-top " style="z-index: 999 !important;">

   <nav class="navbar navbar-expand-lg navbar-light bg-light  ">
        <div class="navbar-brand" style="margin-left: 70px;">
            <a class="navbar-brand" href="#">
                <img src="assets/images/logo.png" style="width: 9rem;">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav ml-auto" style="margin-right: 70px;">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" href="https://www.cuponeravirtual.com.mx/">HOME</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/coupons">CUPONES</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/category">CATEGORÍAS</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/empresas">EMPRESAS</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" href="https://www.cuponeravirtual.com.mx/blog/">BLOG</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" href="https://www.cuponeravirtual.com.mx/contacto/">CONTACTO</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="!metadata.isLoggued">
                    <a class="nav-link" routerLink="/login">LOGIN</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="metadata.isLoggued" [matMenuTriggerFor]="menu" style="cursor:pointer;">
                    <a class="nav-link" style="display: flex;">{{metadata.username}}&nbsp; <span class="material-icons">keyboard_arrow_down</span></a>
                </li>&nbsp;&nbsp;&nbsp;&nbsp;
            </ul>
        </div>
    </nav>
    <app-cintillo (click)="sendToLogin();"></app-cintillo>

    </div>
    <mat-menu #menu="matMenu" class="pt-3">
      <button mat-menu-item routerLink="/mis/cupones"><span class="material-icons">local_offer</span>&nbsp; Mis cupones</button>
      <button mat-menu-item (click)="logout();"> <span class="material-icons">exit_to_app</span>&nbsp; Salir</button>
    </mat-menu>
