import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/directive/loading/loading.service';
import { SessionService } from '../../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { CompanyBranchComponent } from '../../../directive/company-branch/company-branch.component';
import { WebCouponsAlertComponent } from '../web-coupons-alert/web-coupons-alert.component';

@Component({
  selector: 'app-coupons-details',
  templateUrl: './coupons-details.component.html',
  styleUrls: ['./coupons-details.component.css']
})
export class CouponsDetailsComponent implements OnInit {

  couponMin = {
    max: 10,
    page: 1,
    status:true,
    company:{
      id: ''
    },
    category:{
      id: ''
    },
    id:'',
    profileCoupon:{
      id:''
    }
  };
  coupon: any = {};
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  company:any = {
    id:'',
    name:'',
    logotipo:''
  }

  pageEvent: PageEvent;
  profileCouponModel = {
    id:'',
    use_date:'',
    coupon:{
      id:''
    },
    profile:{
      id:''
    },
    company:{
      id:''
    },
    used:false
  }
  profile={
    id:'',
    name:'',
    user:{
      id:''
    }
  }
  profileCoupon = JSON.parse(JSON.stringify(this.profileCouponModel));
  metadata = {
    download:0,
    limit:0
  }
  constructor(
    private session: SessionService,
    private location: Location,
    public loadding: LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private activatedRouted: ActivatedRoute,
    public router:Router
) {}

ngOnInit(): void{
  this.activatedRouted.params.subscribe((params: Params)=>{
    if(params['id'] == 'new'){
    }else{
      this.couponMin.id = params['id'];
      this.getCoupon();
    }
  },error=>{
    console.log("activatedRoute:error",error);
  })
}


   getCoupon(){
     this.loadding.show(true,"Espere un momento...");
     this.session.postRequest("coupon:get",this.couponMin).subscribe(async(data:any)=>{
      this.coupon = data.object;
      this.metadata.download = await this.getCouponsDowlods(this.coupon);
      this.metadata.limit = data.object.limit;
      console.log(this.metadata);
      let loggin = this.session.getLoggedIn();
      if(loggin){
        this.getProfileCoupon();
      }
      //obtenemos los datos de company
      this.getCompany(this.coupon.company.id);
      console.log(data);
      this.loadding.hide();
    },error=>{
      console.log("error:company:list",error);
      this.loadding.hide();
    })
  }

async obtenerCupon(){
  let loggin = this.session.getLoggedIn();
  if(loggin){
    let user = this.session.getSession().user;
    console.log(user);
    if(user.id != ''){
      if(this.profileCoupon.id == ''){
        this.loadding.show(true,"Espere un momento...");
        await this.createProfileCoupon();
        this.couponMin.profileCoupon.id = this.profileCoupon.id;
        this.session.postRequest("coupon:download",this.couponMin).subscribe((data:any)=>{
          let base64 = 'data:image/jpeg;base64,'+data.object;
          fetch(base64)
          .then((res) => {
            res.blob().then((b)=>{
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(b);
              link.download = 'cupon.jpg';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.loadding.hide();
            });

          })
          .then(console.log)
          this.loadding.hide();
          //enviamos el correo de notificacion de descarga de cupón
          this.sendEmailToAdmin();
        },error=>{
          this.loadding.hide();
        });

        // const DATA = document.getElementById('cupons');
        // const doc = new jsPDF('p', 'pt', 'a4');
        // const options = {
        //   background: 'white',
        //   scale: 8
        // };
        // html2canvas(DATA, options).then((canvas) => {
        //
        //   const img = canvas.toDataURL('image/PNG');
        //
        //   // Add image Canvas to PDF
        //   const bufferX = 15;
        //   const bufferY = 15;
        //   const imgProps = (doc as any).getImageProperties(img);
        //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        //   return doc;
        // }).then((docResult) => {
        //   docResult.save(`${new Date().toISOString()}_cupon.pdf`);
        //   this.loadding.show(false,"Espere un momento...");
        // });
      }else{
        this.snackBar.open('Ya descargaste este cupón', 'Error', {duration: 5000});
      }
    }else{
      this.snackBar.open('Logueate para poder hacer uso del copón', 'Error', {duration: 5000});
      this.showAlert();
    }
  }else{
    this.snackBar.open('Logueate para poder hacer uso del copón', 'Error', {duration: 5000});
    this.showAlert();
  }
}

/**funcionalidad para crear el elace de cupon con perfil
*/
async createProfileCoupon(){
  return new Promise(async(resolve,reject)=>{
    await this.getProfile();
    this.profileCoupon.coupon.id = this.coupon.id;
    this.profileCoupon.profile.id = this.profile.id;
    this.profileCoupon.company.id = this.coupon.company.id;
    this.profileCoupon.use_date = moment().format('YYYY/MM/DD');
    this.session.postRequest("profileCoupon:update",this.profileCoupon).subscribe((data:any)=>{
      resolve(true);
      this.profileCoupon.id = data.object.id;
    },error=>{
      reject(error);
      console.log(error);
    })
  });

}

/**funcionalidad para obtener el perfil de un usuario
*/
getProfile(){
  return new Promise((resolve,reject)=>{
    this.profile.user.id = this.session.getSession().user.id;
    this.session.postRequest("profile:findByUser",this.profile).subscribe((data:any)=>{
      this.profile = JSON.parse(JSON.stringify(data.object));
      resolve(true)
    },error=>{
      reject(error);
    })
  });
}


/**funcionalidad para obtener el perfil y copon de un usuario
*/
 async getProfileCoupon(){
  await this.getProfile();
  this.profileCoupon.coupon.id = this.coupon.id;
  this.profileCoupon.profile.id = this.profile.id;
  this.profileCoupon.company.id = this.coupon.company.id;

  this.loadding.show(true,"Espere un momento...");
  this.session.postRequest("profileCoupon:findByProfileAndCoupon",this.profileCoupon).subscribe((data:any)=>{
    if(data.object.id){
      this.profileCoupon = data.object;
    }
    this.loadding.hide();
  },error=>{
    console.log(error);
    this.loadding.hide();
  })
}

  back() {
    this.location.back();
  }

  /**función para obtener los cupones descargados
  */
  getCouponsDowlods(object):Promise<number>{
    return new Promise((resolve,reject)=>{
      this.session.postRequest("profileCoupon:findAllByCouponTotal",{ max: 100,page: 1,coupon:{id:object.id}}).subscribe((data:any)=>{
        resolve(data.object.total);
      },error=>{
        console.log("error:company:list",error);
        reject(error);
      })
    });
  }

  getCompany(companyId){
    this.session.postRequestAnonimus("company:get",{id:companyId}).subscribe((data:any)=>{
      this.company = data.object;
      console.log(data);
    },error=>{
      console.log(error);
    });
  }


  /**función para descargar imagen de cliente
  */
  downloadACuponImage(){
    let elem = document.createElement('a');
    elem.href = this.coupon.img;
    elem.setAttribute('download', this.coupon.img);
    elem.setAttribute('target', '_blank');
    document.body.appendChild(elem);
    elem.click();
  }

  openModalAddress(){
    const dialogRef = this.dialog.open(CompanyBranchComponent, {
      width: '56rem',
      height: '75%',
      data: this.coupon.company,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto

        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera

      }
    });
  }
  sendEmailToAdmin(){
    this.session.postRequestAnonimus("profileCoupon:sendEmailToCompany",this.profileCoupon).subscribe((data:any)=>{
      // console.log(data);
    },error=>{
      console.log(error);
    })
  }

  showAlert(){
    const dialogRef = this.dialog.open(WebCouponsAlertComponent, {
      width: '36rem',
      data: {},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.router.navigate(['/login']);
        }else{
          // El modal se cerro sin objeto

        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera

      }
    });
  }

  sendToCoupones(){
    this.router.navigate(['/empresas/company/'+this.company.id]);
  }

}
