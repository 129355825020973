<app-company-toolbar></app-company-toolbar>

  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Cupones</h2>
          <p> Cupones disponibles para crear: {{metadata.cuponesDisponibles}} </p>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" (click)="update('new');" [disabled]="!metadata.enabledCupon"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Cupon</button>
        </div>
      </div>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Vigencia</th>
            <th>Promoción</th>
            <th>Email</th>
            <th>Tel</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>{{object.vigency}}</td>
            <td>{{object.promotion_description}}</td>
            <td>{{object.email}}</td>
            <td>{{object.phone}}</td>
            <td>
              <span *ngIf="object.vigency != '2020-02-28'">Habilitado</span>
              <span *ngIf="object.vigency == '2020-02-28'">Deshabilitado</span>
            </td>
            <td>
              <div class="table-actions">
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id);" ><mat-icon>edit</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Deshabilitar" (click)="disableCupon(object);" *ngIf="object.vigency != '2020-02-28'"><mat-icon>delete</mat-icon></button>
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Habilitar" (click)="enabledCupon(object);" *ngIf="object.vigency == '2020-02-28'"><mat-icon>preview</mat-icon></button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
