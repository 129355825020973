<section class="cintilloAnimation">
  <div class="cintillo-flex">
    <div class="newbanner">
      <img src="assets/mini-baner.jpeg" alt="banner" style="    height: 100%;">
    </div>
    <!-- <div class="imgcinti">
      <img src="assets/images/registrate3.jpg" alt="Registrate1">
    </div>
    <div class="imgcinti">
      <img src="assets/images/registrate2.jpg" alt="Registrate1">
    </div>
    <div class="imgcinti">
      <img src="assets/images/registrate1.jpg" alt="Registrate1">
    </div> -->
  </div>
</section>
