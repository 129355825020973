import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-coupons-alert',
  templateUrl: './web-coupons-alert.component.html',
  styleUrls: ['./web-coupons-alert.component.css']
})
export class WebCouponsAlertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WebCouponsAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router:Router
  ) { }

  ngOnInit(): void {
  }

  sendToRegister(){
    this.close(false);
    this.router.navigate(['/registro']);
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'alert:001';
    }
    this.dialogRef.close(object);
  }

}
