import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { KsfUploadFilesModule } from './directive/ksf-upload-files/ksf-upload-files.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MenuComponent } from './weeb/menu/menu.component';
import { EmpresasComponent } from './weeb/empresas/empresas.component';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChartsModule } from 'ng2-charts';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { FooterComponent } from './weeb/footer/footer.component';
import { RegistroEmpresasComponent } from './weeb/registro-empresas/registro-empresas.component';
import { SuperuserToolbarComponent } from './superuser/superuser-toolbar/superuser-toolbar.component';
import { SuperuserCompanyComponent } from './superuser/superuser-company/superuser-company.component';
import { SuperuserCompanyUpdateComponent } from './superuser/superuser-company/superuser-company-update/superuser-company-update.component';
import { SuperuserCompanyUserComponent } from './superuser/superuser-company/superuser-company-user/superuser-company-user.component';
import { CompanyToolbarComponent } from './company/company-toolbar/company-toolbar.component';
import { CompanyCouponsComponent } from './company/company-coupons/company-coupons.component';
import { CompanyCouponsUpdateComponent } from './company/company-coupons/company-coupons-update/company-coupons-update.component';
import { SuperuserCategoryComponent } from './superuser/superuser-category/superuser-category.component';
import { SuperuserCategoryUpdateComponent } from './superuser/superuser-category/superuser-category-update/superuser-category-update.component';
import { WebCouponsComponent } from './weeb/web-coupons/web-coupons.component';
import { WebCategoryComponent } from './weeb/web-category/web-category.component';
import { SuperuserCouponsComponent } from './superuser/superuser-coupons/superuser-coupons.component';
import { SuperuserCouponsUpdateComponent } from './superuser/superuser-coupons/superuser-coupons-update/superuser-coupons-update.component';
import { CouponsCompanyComponent } from './weeb/empresas/coupons-company/coupons-company.component';
import { CouponsCategoryComponent } from './weeb/web-category/coupons-category/coupons-category.component';
import { CouponsDetailsComponent } from './weeb/web-coupons/coupons-details/coupons-details.component';
import { MisCuponesComponent } from './weeb/menu/mis-cupones/mis-cupones.component';
import { DownloadedCouponsComponent } from './company/downloaded-coupons/downloaded-coupons.component';
import { CompanyMetricsComponent } from './company/company-metrics/company-metrics.component';
import { DownloadedCouponsChangeComponent } from './company/downloaded-coupons/downloaded-coupons-change/downloaded-coupons-change.component';
import { SuperuserBranchComponent } from './superuser/superuser-company/superuser-branch/superuser-branch.component';
import { SuperuserBranchUpdateComponent } from './superuser/superuser-company/superuser-branch-update/superuser-branch-update.component';
import { CompanyBranchComponent } from './directive/company-branch/company-branch.component';
import { SuperuserPackagesComponent } from './superuser/superuser-packages/superuser-packages.component';
import { SuperuserPackagesUpdateComponent } from './superuser/superuser-packages/superuser-packages-update/superuser-packages-update.component';
import { SuperuserCouponsViewComponent } from './superuser/superuser-company/superuser-coupons-view/superuser-coupons-view.component';
import { CintilloComponent } from './weeb/cintillo/cintillo.component';
import { WebCouponsAlertComponent } from './weeb/web-coupons/web-coupons-alert/web-coupons-alert.component';
import { SuperuserRequestcompanyComponent } from './superuser/superuser-requestcompany/superuser-requestcompany.component';
import { SuperuserRequestcompanyUpdateComponent } from './superuser/superuser-requestcompany/superuser-requestcompany-update/superuser-requestcompany-update.component';
import { SuperuserProfileComponent } from './superuser/superuser-profile/superuser-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistroComponent,
    MenuComponent,
    EmpresasComponent,
    FooterComponent,
    RegistroEmpresasComponent,
    SuperuserToolbarComponent,
    SuperuserCompanyComponent,
    SuperuserCompanyUpdateComponent,
    SuperuserCompanyUserComponent,
    CompanyToolbarComponent,
    CompanyCouponsComponent,
    CompanyCouponsUpdateComponent,
    SuperuserCategoryComponent,
    SuperuserCategoryUpdateComponent,
    WebCouponsComponent,
    WebCategoryComponent,
    SuperuserCouponsComponent,
    SuperuserCouponsUpdateComponent,
    CouponsCompanyComponent,
    CouponsCategoryComponent,
    CouponsDetailsComponent,
    MisCuponesComponent,
    DownloadedCouponsComponent,
    CompanyMetricsComponent,
    DownloadedCouponsChangeComponent,
    SuperuserBranchComponent,
    SuperuserBranchUpdateComponent,
    CompanyBranchComponent,
    SuperuserPackagesComponent,
    SuperuserPackagesUpdateComponent,
    SuperuserCouponsViewComponent,
    CintilloComponent,
    WebCouponsAlertComponent,
    SuperuserRequestcompanyComponent,
    SuperuserRequestcompanyUpdateComponent,
    SuperuserProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatAutocompleteModule,
    ChartsModule,
    SocialLoginModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    KsfUploadFilesModule.forRoot()
  ],
  providers: [
    {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          '744883480924-q492s0kd180e3eoeg9dlv2rvv2cglaa2.apps.googleusercontent.com'
        )
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(
          '981532835721023'
        )
      },
    ],
    onError: (err) => {
      console.error(err);
    }
  } as SocialAuthServiceConfig,
}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
