import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyCouponsUpdateComponent } from './company-coupons-update/company-coupons-update.component';
import * as moment from 'moment';

@Component({
  selector: 'app-company-coupons',
  templateUrl: './company-coupons.component.html',
  styleUrls: ['./company-coupons.component.css']
})
export class CompanyCouponsComponent implements OnInit {

  couponMin = {
    max: 10,
    page: 1,
    status:true,
    company:{
      id:''
    }
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  company = {
    id:''
  }
  metadata = {
    enabledCupon:true,
    cuponesDisponibles:0
  }
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    await this.getCompany();
    this.getList();
    this.packageCupon()
  }

  packageCupon(){
    this.session.postRequest("packageCompany:findAllByCompanyAndActive",{company:{id:this.company.id},active:true}).subscribe((data:any)=>{
      let packages:any = [];
      packages = data.object.instanceList;
      if(packages.length == 0){
        this.metadata.enabledCupon = false;
      }else{
        this.metadata.enabledCupon = packages[0].active;
        if(this.metadata.enabledCupon){
          if(packages[0].quantity > this.list.length){
            this.metadata.enabledCupon = true;
            this.metadata.cuponesDisponibles = packages[0].quantity - this.list.length;
          }else{
            this.metadata.enabledCupon = false;
          }
        }
      }
    },error=>{
      console.log("error:packageCompany:findAllByCompanyAndActive",error);
    })
  }

  async getCompany(){
    return new Promise((resolve,reject)=>{
      let user = this.session.getSession().user;
      this.session.postRequest("user:findByUser",{user:user}).subscribe((data:any)=>{
        this.company = data.object.assignment;
        resolve(true);
      },error=>{
        console.log("error:company:list",error);
        reject(error);
      })
    });
  }

  /**función para obtener el listado de categorias
  */
  getList(){
    this.couponMin.company.id = this.company.id;
    this.session.postRequest("coupon:findAllByCompany",this.couponMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:company:list",error);
    })
  }

  /** funcionalidad para crear una categoria
  */
  update(uuid:string){
    const dialogRef = this.dialog.open(CompanyCouponsUpdateComponent, {
      width: '70%',
      height: '95%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getList();
          this.packageCupon();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  delete(object:any){
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("company:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:company:delete",error);
      this.loadding.hide();
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.couponMin.max = event.pageSize;
    this.couponMin.page = event.pageIndex + 1;
    this.getList();
  }

  /**funcionalida para dehabilitar un cupon.
  */
  disableCupon(object){
    this.loadding.show(true,'Guardando registro...');
    object.vigency = '2020-02-28';
    this.session.postRequest("coupon:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El cupón se deshabilito', '', {
          duration: 8000
        });
        this.loadding.hide();
      },
      error => {
        console.log("Error: coupon:update",error);
        this.loadding.hide();
      }
    );
  }

  enabledCupon(object){
    this.loadding.show(true,'Guardando registro...');
    object.vigency = moment().add(1,'months').format('YYYY-MM-DD');
    this.session.postRequest("coupon:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El cupón se deshabilito', '', {
          duration: 8000
        });
        this.loadding.hide();
      },
      error => {
        console.log("Error: coupon:update",error);
        this.loadding.hide();
      }
    );
  }


}
