<app-menu></app-menu>
<div class="container downMenu" style="padding: 4rem 0rem;">
  <div style="text-align:center">
      <p class="tittle">Categorías</p>
  </div>
  <div class="row">
    <div class="col-md-4 mb-3" *ngFor="let object of list; let i = index;">
      <mat-card class="p-0 mt-1 product-list category" [routerLink]="['/coupons/category', object.id]">
        <img [src]="object.image" class="card-img-top img-fluid">
        <p class="text-uppercase p-2 text-category"><span class="material-icons pr-3">emoji_food_beverage</span>{{object.name}}</p>
      </mat-card>
    </div>
  </div>
</div>
<app-footer></app-footer>
