import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/directive/loading/loading.service';
import { SessionService } from '../../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyBranchComponent } from '../../../directive/company-branch/company-branch.component';

@Component({
  selector: 'app-coupons-company',
  templateUrl: './coupons-company.component.html',
  styleUrls: ['./coupons-company.component.css']
})
export class CouponsCompanyComponent implements OnInit {
  couponMin = {
    max: 100,
    page: 1,
    status:true,
    company:{
      id: ''
    }
  };
  list: any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  companyMin = {
    max: 10,
    page: 1,
    status:true,
    company: {
      id: ''
    },
    id:''
  }
  company:any = {};
  pageEvent: PageEvent;

  branchList = [];
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private activatedRouted: ActivatedRoute) {
               }

  ngOnInit(): void {
    //this.getCompany();

    this.activatedRouted.params.subscribe((params: Params)=>{
      if(params['id'] == 'new'){
      }else{
        this.companyMin.id = params['id'];
        this.couponMin.company.id = params['id'];
        this.getCompany();
        this.getBranchs();
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  getList(){
    // this.getCompany();
    this.session.postRequest('coupon:findAllByCompanyPublic', this.couponMin).subscribe((data: any) => {
     this.list = data.object.instanceList;
   }, error => {
     console.log('error:coupon:findAllByCompanyPublic', error);
   });
 }

 getCompany(){
   this.getList();
   this.loadding.show(true,"Espere un momento...");
   this.session.postRequest('company:get', this.companyMin).subscribe((data: any) => {
    this.company = data.object;
    this.loadding.hide();
    console.log(data);
  }, error => {
    console.log('error:company:get', error);
  });
}

getBranchs(){
  this.session.postRequestAnonimus('branch:findAllByCompany', this.couponMin).subscribe((data: any) => {
   this.branchList = data.object.instanceList;
 }, error => {
   console.log('error:company:get', error);
 });
}

goToLink(url: string){
    window.open(url, "_blank");
}

openModalAddress(){
  const dialogRef = this.dialog.open(CompanyBranchComponent, {
    width: '36rem',
    height: '50%',
    data: this.company,
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result != undefined ){
      if(result.transaction == 'ok'){
        // El modal se cerro con objeto
      }else{
        // El modal se cerro sin objeto

      }
    }else{
      // El modal se cerro sin seleccionar algo, dandole click fuera

    }
  });
}

}
