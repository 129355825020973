<app-menu></app-menu>
<div class="card tam downMenu">
<div class="container rounded shadow color">
  <div class="row">
    <div class="col bgimg d-none d-lg-block col-md-5 col-lg-5 col-xl-6">

    </div>
    <div class="col rounded-end">
      <div class="text-end">
        <h2 class="fw-bold text-center" style="padding: 20px;">Bienvenido</h2>
        <mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabChange($event);">
          <mat-tab label="Usuarios"></mat-tab>
          <mat-tab label="Empresas"></mat-tab>
        </mat-tab-group>

        <form action="#" [formGroup]="contacto" (ngSubmit)="onSubmit()">
          <div class="mb-2 form-group">
            <label for="email" class="form-label">Correo electrónico</label>
            <input type="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid':submitted && f.email.errors}" [(ngModel)]="object.email" >
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Campo requerido</div>
          </div>
          </div>

          <div class="mb-2 form-group">
            <label for="password" class="form-label">Password</label>
            <input type="password" class="form-control" formControlName="password" [ngClass]="{'is-invalid':submitted && f.password.errors}" [(ngModel)]="object.password">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Campo requerido</div>
          </div>
          </div>

          <div class="mb-2 form-check">
            <input type="checkbox" name="connected" class="form-check-input">
            <label for="connected" class="form-check-label">Mantenerme conectado</label>
          </div>

          <div class="row">
            <div class="col">

                <button type="submit" class="btn btn-primary w-100" (click)="send(object);">Iniciar Sesión</button>

            </div>
            <div class="col">

                <button type="submit" class="btn btn-primary w-100" routerLink="/registro" *ngIf="metadata.tab == 'Usuarios'">Registrarse</button>
                <button type="submit" class="btn btn-primary w-100" routerLink="/registro/empresas" *ngIf="metadata.tab == 'Empresas'">Solicita Unirte</button>

            </div>
          </div>


          <div class="my-3">
            <span><a href="#">Recuperar contraseña</a></span>
          </div>
        </form>

        <div class="container w-100 my-3" *ngIf="metadata.tab == 'Usuarios'">
          <div class="row text-center">
            <div class="col-12">Iniciar Sesión con</div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-outline-primary w-100 my-2" (click)="signInWithFacebook();">
                <div class="row align-items-center">
                    <div class=" col-10 col-md-12">
                      <img src="/assets/images/face.png" width="28" alt="">
                    </div>
                    <div class="col-12 text-center d-none d-md-block">
                      Facebook
                    </div>
                </div>
              </button>
            </div>
            <div class="col">
              <button class="btn btn-outline-danger w-100 my-2" (click)="signInWithGoogle();">
                <div class="row align-items-center">
                    <div class="col-10 col-md-12">
                      <img src="/assets/images/google.png" width="28" alt="">
                    </div>
                    <div class="col-12 text-center d-none d-md-block">
                      Google
                    </div>

                </div>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<app-footer></app-footer>
