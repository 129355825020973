<app-menu></app-menu>
<div class="card conten text-center downMenu">
  <div class="card-bod">
    <div class="row justify-content-center m-0" style="padding-top: 1rem; padding-bottom: 1rem; he">
        <div class="col-md-8 col-11" style="padding: 1rem 0rem;">
            <p  class="tittle-search">Tu empresa a un Click de distancia</p>
        </div>
    </div>
  </div>
</div>
<div class="container " style="padding: 4rem 0rem;" *ngIf="!metadata.registrate">
  <div class="row justify-content-center">
    <div class="col-11 col-md-10 cardRegister">
      <p style="font-size: 18pt; color: #706ed9;">Solicita tu registro</p>
      <p style="font-size: 12pt;"> Bienvenid@ a <strong>Cuponera Virtual</strong>, actualmente el <strong>registro</strong> a <strong>Cuponera Virtual</strong>  es por solicitud.</p>
      <ul style="font-size: 12pt;">
        <li>Completa el siguiente formulario.</li>
        <li>Dale click al botón enviar.</li>
        <li>Nos pondremos en contacto contigo para continuar con el proceso de registro.</li>
      </ul>
      <p style="font-size: 12pt;"> Ofrece a tus clientes este beneficio: Cupones de Descuento y Promociones de las principales empresas de Oaxaca, de una manera práctica, económica y ecológica, promoviendo el consumo local, donde encontrarán diferentes productos y servicios de las mejores marcas.  </p>
      <br>
      <div class="row">
        <div class="col-12">
          <p>Ingresa los datos requeridos del formulario.</p>
        </div>
        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Nombre Empresa.</mat-label>
          <input matInput placeholder="Nombre" name="name" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Razón Social.</mat-label>
          <input matInput placeholder="Razon Social" name="social_reason" [(ngModel)]="object.social_reason" [formControl]="complexForm.controls['social_reason']" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['social_reason'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Giro.</mat-label>
          <input matInput placeholder="Giro" name="giro" [(ngModel)]="object.giro" [formControl]="complexForm.controls['giro']" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['giro'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Especialidad.</mat-label>
          <input matInput placeholder="area" name="area"  [(ngModel)]="object.area" >
          <!-- <mat-hint>
            <mat-error *ngIf="complexForm.controls['area'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Eslogan</mat-label>
          <textarea matInput placeholder="Eslogan" name="slogan" [(ngModel)]="object.slogan" ></textarea>
          <!-- <mat-hint>
            <mat-error *ngIf="complexForm.controls['slogan'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Breve Reseña</mat-label>
          <textarea matInput placeholder="Breve Reseña" name="abstract" [(ngModel)]="object.abstract" ></textarea>
          <!-- <mat-hint>
            <mat-error *ngIf="complexForm.controls['abstract'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint> -->
        </mat-form-field>


        <p class="col-9">Datos de contacto.</p>
        <mat-form-field appearance="outline" class="col-12 col-md-5">
          <mat-label>WhastApp.</mat-label>
          <input matInput placeholder="WhastApp" name="whastapp"  [(ngModel)]="object.whastapp" [formControl]="complexForm.controls['whastapp']" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['whastapp'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>website.</mat-label>
          <span matPrefix>https:// &nbsp;</span>
          <input matInput placeholder="website" name="website" [(ngModel)]="object.website" >
          <!-- <mat-hint>
            <mat-error *ngIf="complexForm.controls['website'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-5">
          <mat-label>Tel.</mat-label>
          <input matInput placeholder="Tel" name="phone" [(ngModel)]="object.phone" [formControl]="complexForm.controls['phone']" required >
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Email.</mat-label>
          <input matInput placeholder="Email" name="email" [(ngModel)]="object.email"  [formControl]="complexForm.controls['email']"  required >
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['email'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Dirección</mat-label>
          <textarea matInput placeholder="Dirección" name="address"  [(ngModel)]="object.address" [formControl]="complexForm.controls['address']"  required></textarea>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['address'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-5">
          <mat-label>Facebook.</mat-label>
          <input matInput placeholder="Facebook" name="facebook" [(ngModel)]="object.facebook">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Instagram.</mat-label>
          <input matInput placeholder="Instagram" name="instagram"  [(ngModel)]="object.instagram">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-5">
          <mat-label>Twitter.</mat-label>
          <input matInput placeholder="Twitter" name="twitter" [(ngModel)]="object.twitter">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Linkedin.</mat-label>
          <input matInput placeholder="linkedin" name="linkedin" [(ngModel)]="object.linkedin">
        </mat-form-field>

        <p class="col-9">Personal de contacto.</p>

        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Nombre Titular.</mat-label>
          <input matInput placeholder="Nombre" name="name_contact" [(ngModel)]="object.name_contact"  [formControl]="complexForm.controls['name_contact']"  required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['name_contact'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Tel.</mat-label>
          <input matInput placeholder="Tel" name="phone_contact"  [(ngModel)]="object.phone_contact" [formControl]="complexForm.controls['phone_contact']"  required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['phone_contact'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Email.</mat-label>
          <input matInput placeholder="Email" name="email_contact"  [(ngModel)]="object.email_contact" [formControl]="complexForm.controls['email_contact']"  required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['email_contact'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>


        <!-- <div class="col-8">
         <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
         <div class="cardImg">
           <span *ngIf="metadata.img.name == ''">Sube tu logo</span>
           <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
           <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
           <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
           <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
           <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
           <mat-hint *ngIf="metadata.img.name == ''">
             Imagen en formato PNG ó JPG de 1280 x 1280 pixeles;
           </mat-hint>

         </div>
       </div> -->

       <div class="col-12 col-md-9 mt-2">
         <p>Sube la imagen de tu logotipo</p>
         <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/png, image/jpeg" />
         <div class="cardFileBox">
           <div class="row justify-content-end" *ngIf="metadata.uploadted">
             <div class="col-3">
               <button type="button" class="btn btn-warning mt-3" style="width: 100%;" (click)="deleteImg();">Eliminar</button>
             </div>
           </div>
           <div class="centerImag" *ngIf="metadata.img.name == '' && !metadata.uploadted" (click)="loadImg();">
             <img src="assets/images/upload.png" alt="Subir documento">
             Click aqui para seleccionar una imagen.
           </div>
           <div class="centerImag" *ngIf="metadata.img.name != ''" (click)="download();">
             <img src="assets/images/dowload.png" alt="Subir documento">
             Click aqui para descargar la imagen. <strong style="color: #dc2378;">{{metadata.img.name}}</strong>
           </div>
         </div>
         <mat-hint *ngIf="metadata.img.name == ''">
           Imagen en formato PNG ó JPG de 1280 x 1280 pixeles;
         </mat-hint>
       </div>



      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-4">
          <button mat-flat-button color="warn" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Enviar Solicitud</button>
        </div>
      </div>

    </div>

  </div>
</div>
<div class="container " style="padding: 4rem 0rem;" *ngIf="metadata.registrate">
  <div class="row justify-content-center">
    <div class="col-11 col-md-10 cardRegister">
      <div class="imgBoc">
        <img src="assets/images/logo.png" alt="logo">
      </div>
      <p style="color: #7b67de;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 45px;">Gracias por enviarnos tu solicitud</p>
      <p style="color: #8ae3bf;
    font-size: 1.6rem;
    text-align: center;
    margin-top: 45px;">Nos pondremos en contacto a la brevedad.</p>
    <button mat-flat-button color="accent" routerLink="/" class="btnD">Regresar</button>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="margin-bottom: -30px; margin-left: -30px; margin-right: -30px;"><path fill="#48c794" fill-opacity="1" d="M0,224L48,208C96,192,192,160,288,160C384,160,480,192,576,213.3C672,235,768,245,864,240C960,235,1056,213,1152,197.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    </div>
  </div>
</div>
<app-footer></app-footer>
