import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard'
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { EmpresasComponent } from './weeb/empresas/empresas.component';
import { SuperuserCompanyComponent } from './superuser/superuser-company/superuser-company.component';
import { SuperuserCouponsComponent } from './superuser/superuser-coupons/superuser-coupons.component';
import { CompanyCouponsComponent } from './company/company-coupons/company-coupons.component';
import { SuperuserCategoryComponent} from './superuser/superuser-category/superuser-category.component';
import { WebCategoryComponent } from './weeb/web-category/web-category.component';
import { WebCouponsComponent } from './weeb/web-coupons/web-coupons.component';
import { CouponsCompanyComponent } from './weeb/empresas/coupons-company/coupons-company.component';
import { CouponsCategoryComponent } from './weeb/web-category/coupons-category/coupons-category.component';
import { CouponsDetailsComponent } from './weeb/web-coupons/coupons-details/coupons-details.component';
import { MisCuponesComponent } from './weeb/menu/mis-cupones/mis-cupones.component';
import { DownloadedCouponsComponent } from './company/downloaded-coupons/downloaded-coupons.component';
import { CompanyMetricsComponent } from './company/company-metrics/company-metrics.component';
import { SuperuserBranchComponent } from './superuser/superuser-company/superuser-branch/superuser-branch.component';
import { SuperuserPackagesComponent } from './superuser/superuser-packages/superuser-packages.component';
import { RegistroEmpresasComponent } from './weeb/registro-empresas/registro-empresas.component';
import { SuperuserRequestcompanyComponent } from './superuser/superuser-requestcompany/superuser-requestcompany.component';
import { SuperuserProfileComponent } from './superuser/superuser-profile/superuser-profile.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'registro', component: RegistroComponent },
    { path: 'registro/empresas', component: RegistroEmpresasComponent },
    { path: 'empresas', component: EmpresasComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}  },
    { path: 'mis/cupones', component: MisCuponesComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}  },
    { path: 'empresas/company/:id', component: CouponsCompanyComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}  },
    { path: 'coupons', component: WebCouponsComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}  },
    { path: 'coupons/details/:id', component: CouponsDetailsComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}  },
    { path: 'category', component: WebCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}   },
    { path: 'coupons/category/:id', component: CouponsCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']}   },
    { path: 'superuser/coupons', component: SuperuserCouponsComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/company', component: SuperuserCompanyComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/requestcompany', component: SuperuserRequestcompanyComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/packages', component: SuperuserPackagesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/company/branch/list/:id', component: SuperuserBranchComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/category', component: SuperuserCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'superuser/profile', component: SuperuserProfileComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
    { path: 'company/coupons', component: CompanyCouponsComponent , canActivate:[AuthenticationGuard], data:{access: ['COMPANY']}  },
    { path: 'company/metrics', component: CompanyMetricsComponent, canActivate:[AuthenticationGuard], data:{access: ['COMPANY']}  },
    { path: 'downloaded/coupons', component: DownloadedCouponsComponent , canActivate:[AuthenticationGuard], data:{access: ['COMPANY']}  },
    { path: 'company/downloaded/coupons', component: DownloadedCouponsComponent , canActivate:[AuthenticationGuard], data:{access: ['COMPANY']}  },
    { path: '**', pathMatch: 'full', redirectTo: 'coupons' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
