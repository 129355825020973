<div class="row justify-content-end">
  <div class="col-10">
    <p class="moradito col-11">Cuponera Virtual</p>
  </div>
  <div class="col-2">
    <button mat-icon-button  class="btnClose" (click)="close(false)" style="float: right;margin-top: -10px;">
          <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div class="row justify-content-center mt-3 mb-3">

  <p class="col-11">Para porder descargar un cupón debes primero iniciar sesion ó registrarte.</p>
  <br>
</div>
<div class="row justify-content-around">
  <button mat-flat-button color="accent" (click)="sendToRegister();" class="col-5">Regístrate</button>
  <button mat-flat-button color="primary" (click)="close(true);" class="col-5"> Iniciar sesión</button>
</div>
