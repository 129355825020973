<app-menu></app-menu>
<div class="card conten text-center downMenu">
    <div class="card-body">
      <div class="row justify-content-center m-0" style="padding-top: 1rem; padding-bottom: 1rem;">
          <div class="col-md-8 col-11" style="padding: 1rem 0rem;">
              <p  class="tittle-search">Todo lo mejor</p>
              <p  class="tittle-search mt-2 mt-md-5">de tus tiendas favoritas </p>
          </div>
      </div>
      <div class="row justify-content-center">
        <div class=" col-md-8 p-0">
          <input class="form-control me-2" type="search" placeholder="Buscar empresa" aria-label="Search" [(ngModel)]="companySearchMin.filter" (ngModelChange)="search();" [matAutocomplete]="autoProduct">
        </div>
        <button class="btn-buscar " type="submit"><span class="material-icons" style="font-size: 18px;">search</span></button>
        <mat-autocomplete autoActiveFirstOption #autoProduct="matAutocomplete">
          <mat-option *ngFor="let object of companySearchList" [value]="object.name" (click)="companySelected(object);">
            <img class="example-option-img" aria-hidden src="{{object.logotipo}}" height="25" style="padding-right: 15px;">
            <span> <strong style="font-size: medium; color: #706ed9;"> {{object.name}} </strong> </span>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
</div>
<div class="container " style="padding: 4rem 0rem;">
  <div style="text-align:center">
      <p class="tittle">Empresas</p>
  </div>
  <div class="row justify-content-center">
    <div class="col-9 col-md-3 text-center mb-3" *ngFor="let object of list; let i = index;">
      <mat-card class="p-0 mt-1 product-list" style="height: 23rem !important;">
        <!-- <img [src]="object.logotipo" class="img-company"> -->
        <div class="frame" [routerLink]="['/empresas/company', object.id]">
          <img [src]="object.logotipo">
        </div>
        <!-- <div class="btn-product">
          <button mat-raised-button color="accent" class="add-shopping text-uppercase">Ver cupones</button>
        </div> -->
        <div class="card-body">
          <p class="mb-1">{{object.name}}</p>

          <!-- <small class="text-uppercase text-secondary">{{object.name}}</small><br> -->
          <a *ngIf="object.facebook"  [href]="object.facebook" target="_blank" class="pr-1"><img src="/assets/images/facebook.png" style="width: 1.8rem;" alt=""></a>
          <a *ngIf="object.twitter" [href]="object.twitter" target="_blank" class="pr-1"><img src="/assets/images/twitter.png" style="width: 1.8rem;" alt=""></a>
          <a *ngIf="object.website"  href="https://{{object.website}}" target="_blank" class="pr-1"><img src="/assets/images/sitioweb.png" style="width: 1.8rem;" alt=""></a>
          <a *ngIf="object.linkedin" [href]="object.linkedin" target="_blank" class="pr-1"><img src="/assets/images/linkedin.png" style="width: 1.8rem;" alt=""></a>
          <a *ngIf="object.instagram" [href]="object.instagram" target="_blank" class="pr-1"><img src="/assets/images/instagram.png" style="width: 1.8rem;" alt=""></a>
        </div>
      </mat-card>
    </div>
  </div>
</div>


<app-footer></app-footer>
