<div class="row justify-content-center" *ngIf="branchList.length > 0">
  <div class="col-12">
    <button mat-icon-button class="naturaColor"  style="font-size: smaller;float: right;" matTooltip="Cerrar" (click)="close(true);" ><mat-icon>clear</mat-icon></button>
  </div>
  <p class="col-12" style="text-align: center; color: #6fdeac;"><strong>SUCURSALES</strong></p>
  <div class="col-12 col-md-6" *ngFor="let object of branchList" style="text-align:center">
    <p><strong>{{object.name}}</strong></p>
    <p><small>{{object.address}}</small></p>
    <div *ngIf="object.iframe != ''" class="frame0">
      <div [innerHTML]="object.frameSRC" class="frame"></div>
      <!-- <iframe [innerHTML]="object.iframe" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
      <!-- <button mat-icon-button color="accent" (click)="goToLink(object.iframe)" [disabled]="object.iframe == ''" matTooltip="Ir a ubicación en Google Maps">
              <mat-icon>pin_drop</mat-icon>
      </button> -->
    </div>
  </div>

</div>
<div class="row" *ngIf="branchList.length == 0">
  <p class="col-12" style="text-align: center; color: #6fdeac;"><strong>SUCURSALES</strong></p>
  <div class="col-12"  style="text-align: center;">
    <p><strong> {{ company.address }}</strong></p>
    <div class="" *ngIf="company.address != ''">
      <!-- <div [innerHTML]="company.address"></div> -->
      <!-- <button mat-icon-button color="accent" (click)="goToLinkCompany(company.address)" matTooltip="Ir a ubicación en Google Maps">
              <mat-icon>pin_drop</mat-icon>
      </button> -->
    </div>
  </div>

</div>
