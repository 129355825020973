import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-company-metrics',
  templateUrl: './company-metrics.component.html',
  styleUrls: ['./company-metrics.component.css']
})
export class CompanyMetricsComponent implements OnInit {
  couponMin = {
    max: 100,
    page: 1,
    status:true,
    company:{
      id:''
    }
  }
  list:any = [];

  company = {
    id:''
  }

  //
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartDataUsed: SingleDataSet = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartColors = [{
    backgroundColor: [
      'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
       'rgba(201,190,10,1)', 'rgba(275,220,10,1)'
    ]
  }];
  public pieChartColors1 = [{
    backgroundColor: [
      'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
       'rgba(201,190,10,1)', 'rgba(275,220,10,1)'
    ]
  }];
  public pieChartColors2 = [{
    backgroundColor: [
       'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
       'rgba(201,190,10,1)', 'rgba(275,220,10,1)','rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
       'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
       'rgba(101,0,36,1)', 'rgba(133,100,2,1)',
    ]
  }];
  public pieChartType: ChartType = 'pie';
  //
  metadata = {
    globalTotalDownloades:0,
    globalTotalUsed:0,
    start:'',
    end:'',
    startDate:'',
    endDate:''
  }

  pieChartByCoupons = [];

  cuponListGlobal = [];


  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    elements: {
        line: {
            tension: 0
        }
    },
    animation:{
      duration:700,
      easing:'easeInCirc'
    },
    scales: {
            yAxes: [
              {
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                },
                type: 'linear',
                position: 'left',
                scaleLabel:{
                  display:true,
                  labelString:'Descargas'
                }
            },
          ],
        }
  };

  public lineChartType: ChartType = 'line';
  public lineChartData: ChartDataSets[] = [];
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public lineChartLabels: Label[] = [];
  public lineChartColors = [
    {
      backgroundColor: 'rgba(30, 49, 99,0.2)',
      borderColor: 'rgba(30, 49, 99,1)',
      pointBackgroundColor: 'rgba(30, 49, 99,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(30, 49, 99,0.8)'
    },
    {
      backgroundColor: 'rgba(240, 55, 165,0.2)',
      borderColor: 'rgba(240, 55, 165,1)',
      pointBackgroundColor: 'rgba(240, 55, 165,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(240, 55, 165,0.8)'
    },
    {
      backgroundColor: 'rgba(254, 251, 243,0.2)',
      borderColor: 'rgba(254, 251, 243,1)',
      pointBackgroundColor: 'rgba(254, 251, 243,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(254, 251, 243,0.8)'
    },
    {
      backgroundColor: 'rgba(181, 222, 255,0.2)',
      borderColor: 'rgba(181, 222, 255,1)',
      pointBackgroundColor: 'rgba(181, 222, 255,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(181, 222, 255,0.8)'
    },
    {
   backgroundColor: 'rgba(252, 255, 166,0.2)',
   borderColor: 'rgba(252, 255, 166,1)',
   pointBackgroundColor: 'rgba(252, 255, 166,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(252, 255, 166,0.8)'
 },
 {
   backgroundColor: 'rgba(193, 255, 215,0.2)',
   borderColor: 'rgba(193, 255, 215,1)',
   pointBackgroundColor: 'rgba(193, 255, 215,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(193, 255, 215,0.8)'
 },
 {
   backgroundColor: 'rgba(202, 184, 255,0.2)',
   borderColor: 'rgba(202, 184, 255,1)',
   pointBackgroundColor: 'rgba(202, 184, 255,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(202, 184, 255,0.8)'
 },
 {
   backgroundColor: 'rgba(121, 180, 183,0.2)',
   borderColor: 'rgba(121, 180, 183,1)',
   pointBackgroundColor: 'rgba(121, 180, 183,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(121, 180, 183,0.8)'
 },
 {
   backgroundColor: 'rgba(200, 92, 92,0.2)',
   borderColor: 'rgba(200, 92, 92,1)',
   pointBackgroundColor: 'rgba(200, 92, 92,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(200, 92, 92,0.8)'
 },
 {
   backgroundColor: 'rgba(249, 151, 93,0.2)',
   borderColor: 'rgba(249, 151, 93,1)',
   pointBackgroundColor: 'rgba(249, 151, 93,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(249, 151, 93,0.8)'
 },
 {
   backgroundColor: 'rgba(251, 209, 72,0.2)',
   borderColor: 'rgba(251, 209, 72,1)',
   pointBackgroundColor: 'rgba(251, 209, 72,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(251, 209, 72,0.8)'
 },
 {
   backgroundColor: 'rgba(178, 234, 112,0.2)',
   borderColor: 'rgba(178, 234, 112,1)',
   pointBackgroundColor: 'rgba(178, 234, 112,1)',
   pointBorderColor: '#fff',
   pointHoverBackgroundColor: '#fff',
   pointHoverBorderColor: 'rgba(178, 234, 112,0.8)'
 }
];



  constructor(private session:SessionService, private loading:LoadingService) { }

  async ngOnInit() {
    let actualMonth = moment().get('month');
    let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');

    this.metadata.startDate =  moment().set('date',1).format('YYYY-MM-DD');
    // this.orderMin.to =  moment().add(1, 'days').format('YYYY-MM-DD');
    this.metadata.endDate  = endOfTheMonth.format('YYYY-MM-DD');
    // colocamos los el value en los datePicker
    this.metadata.start =  moment().set('date',1).toISOString();
    // this.metadata.end =  moment().add(1, 'days').toISOString();
    this.metadata.end =  endOfTheMonth.toISOString();
    await this.getCompany();
    this.getList();
  }

  async getCompany(){
    return new Promise((resolve,reject)=>{
      let user = this.session.getSession().user;
      // console.log(":::::::::",user);
      this.session.postRequest("user:findByUser",{user:user}).subscribe((data:any)=>{
        this.company = data.object.assignment;
        resolve(true);
      },error=>{
        console.log("error:company:list",error);
        reject(error);
      })
    });
  }

  /**función para obtener el listado de categorias
  */
  getList(){
    this.loading.show(true,"Espere un momento...");
    this.couponMin.company.id = this.company.id;
    this.session.postRequest("coupon:findAllByCompany",this.couponMin).subscribe(async(data:any)=>{
      this.metadata.globalTotalDownloades = 0;
      this.cuponListGlobal = data.object.instanceList;
      this.getTimeLine();
      for(let item of data.object.instanceList){
        item.totalDowloades = await this.getCouponsDowlods(item);
        item.totalUsed = await this.getCouponsUsed(item);
        this.list.push(item);
        this.metadata.globalTotalDownloades = this.metadata.globalTotalDownloades + item.totalDowloades;
        this.metadata.globalTotalUsed = this.metadata.globalTotalUsed + item.totalUsed;
      }
      for(let item of this.list){
        this.pieChartLabels.push(item.promotion_description);
        this.pieChartData.push(item.totalDowloades);
        this.pieChartDataUsed.push(item.totalUsed);
        let aux = {
          name:item.promotion_description,
          download:0,
          noDownload:0,
          used:0,
          notUsed:0,
          pieChartLabels:[],
          pieChartData:[],
          pieChartLabelsUsed:[],
          pieChartDataUsed:[]
        }
        aux.download = item.totalDowloades;
        aux.noDownload = item.limit - item.totalDowloades;
        aux.notUsed = item.totalDowloades - item.totalUsed;
        aux.used = item.totalUsed;
        aux.pieChartLabels.push('Descargados');
        aux.pieChartLabels.push('No descargados');

        aux.pieChartData.push(item.totalDowloades);

        aux.pieChartLabelsUsed.push('Sin Utilizar');
        aux.pieChartLabelsUsed.push('Utilizados');
        aux.pieChartDataUsed.push(item.totalDowloades - item.totalUsed);
        aux.pieChartDataUsed.push(item.totalUsed);
        let nDown = item.limit - item.totalDowloades;
        aux.pieChartData.push(nDown);
        this.pieChartByCoupons.push(aux);
      }
      // console.log(this.pieChartByCoupons);
      // console.log(this.list);
      this.loading.hide();
      // this.list = data.object.instanceList;
    },error=>{
      this.loading.hide();
      console.log("error:company:list",error);
    })
  }

  /**función para obtener los cupones utilizamos de una compañia
  */
  getCouponsDowlods(object){
    return new Promise((resolve,reject)=>{
      this.metadata.startDate =  moment(this.metadata.start).format('YYYY-MM-DD');
      // this.orderMin.to =  moment().add(1, 'days').format('YYYY-MM-DD');
      this.metadata.endDate  = moment(this.metadata.end).format('YYYY-MM-DD');
      this.session.postRequest("profileCoupon:findAllByCouponTotal",{startDate:this.metadata.startDate,endDate:this.metadata.endDate, max: 100,page: 1,coupon:{id:object.id}}).subscribe((data:any)=>{
        resolve(data.object.total);
      },error=>{
        console.log("error:company:list",error);
        reject(error);
      })
    });
  }
  getCouponsUsed(object){
    return new Promise((resolve,reject)=>{
      this.metadata.startDate =  moment(this.metadata.start).format('YYYY-MM-DD');
      // this.orderMin.to =  moment().add(1, 'days').format('YYYY-MM-DD');
      this.metadata.endDate  = moment(this.metadata.end).format('YYYY-MM-DD');
      this.session.postRequest("profileCoupon:findAllByUsedTotal",{startDate:this.metadata.startDate,endDate:this.metadata.endDate, max: 100,page: 1,coupon:{id:object.id}}).subscribe((data:any)=>{
        resolve(data.object.total);
      },error=>{
        console.log("error:profileCoupon:findAllByUsedTotal",error);
        reject(error);
      })
    });
  }

  /** funcion a ejecutar cuando cambia una fecha
  */
  changeDate(){
    this.metadata.startDate =  moment(this.metadata.start).format('YYYY-MM-DD');
    this.metadata.endDate  =  moment(this.metadata.end).format('YYYY-MM-DD');
    // console.log("Cambio de periodo");
    this.list = [];
    if(moment(this.metadata.start).diff(moment(this.metadata.end)) < 0){
      // console.log(this.metadata);
      this.getTimeLine();
    }else{
      // this.snackBar.open('Revisar el periodo de tiempo seleccionado.', 'Aceptar', {duration: 5000});
    }
  }


  /**funcionalidad para obtener el time line
  */
  async getTimeLine(){
    this.lineChartData = [];
    let j = 0;
    for(let item of this.cuponListGlobal){
      let singleChartData = {
        data:[],
        label: item.promotion_description
      }
      singleChartData = {...singleChartData, ...this.lineChartColors[j]};
      j++;
      singleChartData.data = await this.getCuponesByTimeLine(item);
      this.lineChartData.push(singleChartData);
    }
    var diffInMs = Math.abs(moment(this.metadata.start).diff(this.metadata.end));
    // console.log(diffInMs);
    let baseDiff = diffInMs /10;
    this.lineChartLabels = [];
    for(let  i = 0; i <= 10; i++){
      let baseDiffAdd:any = baseDiff * (i);
      let aux = moment(this.metadata.start).add(baseDiffAdd,'ms').format('DD/MM/YYYY');
      this.lineChartLabels.push(aux);
    }
    // console.log(this.lineChartData);

  }


  /**funcionalidad para obtner los cupones por time lines
  */
  getCuponesByTimeLine(object):Promise<[]>{
    return new Promise((resolve,reject)=>{
      this.metadata.startDate =  moment(this.metadata.start).format('YYYY-MM-DD');
      // this.orderMin.to =  moment().add(1, 'days').format('YYYY-MM-DD');
      this.metadata.endDate  = moment(this.metadata.end).format('YYYY-MM-DD');
      this.session.postRequest("profileCoupon:findAllByCouponTotalByPeriod",{startDate:this.metadata.startDate,endDate:this.metadata.endDate, max: 100,page: 1,coupon:{id:object.id}}).subscribe((data:any)=>{
        resolve(data.object);
      },error=>{
        console.log("error:company:list",error);
        reject(error);
      })
    });
  }

}
