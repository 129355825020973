import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/directive/loading/loading.service';
import { SessionService } from '../../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-coupons-category',
  templateUrl: './coupons-category.component.html',
  styleUrls: ['./coupons-category.component.css']
})
export class CouponsCategoryComponent implements OnInit {
  couponMin = {
    max: 100,
    page: 1,
    status:true,
    company:{
      id:''
    },
    category:{
      id: ''
    }
  };
  list: any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  category: {
    id: ''
  };
  categoryMin = {
    max: 10,
    page: 1,
    status:true
  }
  listCategory = [];
  pageEvent: PageEvent;
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private activatedRouted: ActivatedRoute) {
               }

  ngOnInit(): void {
    this.activatedRouted.params.subscribe((params: Params) => {
      if(params['id'] == 'new'){
      }else{
        this.couponMin.company.id = params['id'];
        this.getList();
      }
    }, error => {
      console.log('activatedRoute:error', error);
    })
  }

  getList(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest('coupon:findAllByCategory', this.couponMin).subscribe((data: any) => {
     this.list = data.object.instanceList;
     this.loadding.hide();
     console.log(data);
   }, error => {
     console.log('error:coupon:list', error);
   });
 }
}
