import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { SuperuserBranchUpdateComponent } from '../superuser-branch-update/superuser-branch-update.component';

@Component({
  selector: 'app-superuser-branch',
  templateUrl: './superuser-branch.component.html',
  styleUrls: ['./superuser-branch.component.css']
})
export class SuperuserBranchComponent implements OnInit {
  branchList = [];
  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SuperuserBranchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) {}


  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.show(true,"Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequestAnonimus('branch:findAllByCompany',{company:{id:this.data}}).subscribe((data:any)=>{
         this.branchList = data.object.instanceList;
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: branch:findAllByCompany',error)
       })
    }
  }

  update(objectID){
    const dialogRef = this.dialog.open(SuperuserBranchUpdateComponent, {
      width: '60%',
      height: '60%',
      data: {branchID:objectID,companyID:this.data},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.loadingService.show(true,"Espere un momento...");
           // cargamos los datos de la BD
           this.session.postRequestAnonimus('branch:findAllByCompany',{company:{id:this.data}}).subscribe((data:any)=>{
             this.branchList = data.object.instanceList;
             this.loadingService.hide();
           },
           (error)=>{
             this.loadingService.hide();
             console.log('Error: branch:findAllByCompany',error)
           })
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  delete(object){
    this.loadingService.show(true,"Espere un momento...");
    this.session.postRequest("branch:delete",object).subscribe((data:any)=>{
      for(let i = 0; i < this.branchList.length; i++){
        if(this.branchList[i].id == object.id){
          this.branchList.splice(i,1);
          break;
        }
      }
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'company:001';
    }
    this.dialogRef.close(object);
  }


}
