<button mat-icon-button (click)="close(false)" color="accent" style="float: right;">
        <mat-icon>clear</mat-icon>
</button>
<div class="row title-modal">
    <div class="col-1">

    </div>
    <div class="col-6">
      <h3>Nuevo paquete</h3>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-11">
      <div class="row mt-2">
        <div class="col-12">
          <p>Ingresa los datos requeridos del formulario.</p>
        </div>


        <mat-form-field appearance="outline" class="col-12 col-md-9">
          <mat-label>Nombre.</mat-label>
          <input matInput placeholder="Nombre" name="name" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-12">
          <mat-label>Descripción.</mat-label>
          <textarea matInput placeholder="Descripción" name="description" [(ngModel)]="object.description" [formControl]="complexForm.controls['description']" required></textarea>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-12 col-md-6">
          <mat-label>Cantidad de cupones</mat-label>
          <input matInput placeholder="Cantidad de cupones" name="cupons" [(ngModel)]="object.quantity" [formControl]="complexForm.controls['quantity']" required>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['quantity'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>


        <!-- <mat-form-field appearance="outline" class="col-12 col-md-6">
          <mat-label>Vigencia</mat-label>
          <input matInput [matDatepicker]="picker" name="vigency" [(ngModel)]="metadata.vigency" [formControl]="complexForm.controls['vigency']" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>
            <mat-error *ngIf="complexForm.controls['vigency'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field> -->

        <div class="col-12 col-md-6">
          <mat-slide-toggle name="free" [(ngModel)]="object.free">¿ Paquete Gratis ? : <strong *ngIf="object.free">Si</strong> <strong *ngIf="!object.free">No</strong> </mat-slide-toggle>
        </div>
        <div class="col-12 col-md-6" *ngIf="!object.free">
          <mat-slide-toggle name="annual" [(ngModel)]="object.annual">¿ Precio anual ó mensual ? : <strong *ngIf="object.annual">Anual</strong> <strong *ngIf="!object.annual">Mensual</strong> </mat-slide-toggle>
        </div>
        <mat-form-field appearance="outline" class="col-12 col-md-4" *ngIf="!object.free">
          <mat-label>Precio</mat-label>
          <span matPrefix >$ &nbsp;</span>
          <input matInput placeholder="Nombre" name="name"  [(ngModel)]="object.price">
          <span matSuffix *ngIf="!object.annual"> x Mensual</span>
          <span matSuffix *ngIf="object.annual"> x Año</span>
        </mat-form-field>


      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-4">
          <button mat-flat-button color="warn" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Guardar</button>
        </div>
      </div>
    </div>
  </div>
