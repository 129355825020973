<button mat-icon-button (click)="close(false)" color="accent" style="float: right;">
        <mat-icon>clear</mat-icon>
</button>
<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nuevo Cupon</h3>
  </div>
</div>
<div class="row justify-content-center" *ngIf="!metadata.showUpdate">
  <div class="col-12">
    <img src="assets/images/cuponesinfo.jpg" alt="Cupones" style="width: 100%;">
  </div>
</div>
<div class="row justify-content-end mt-4" *ngIf="!metadata.showUpdate">
  <div class="col-4">
    <button mat-flat-button color="warn" style="width:100%;"   (click)="viewNext();">Siguiente</button>
  </div>
</div>
<div class="row justify-content-center" *ngIf="metadata.showUpdate">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field appearance="outline" class="col-12 col-md-6">
        <mat-label>Vigencia</mat-label>
        <input matInput [matDatepicker]="picker" name="vigency" [formControl]="complexForm.controls['vigency']" [(ngModel)]="metadata.vigency" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['vigency'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-6">
        <mat-label>Categoria</mat-label>
        <mat-select name="category" [(ngModel)]="object.category.id">
          <mat-option *ngFor="let object of categoryList" [value]="object.id">{{object.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="outline">
        <mat-label>&nbsp;Limite de cupon</mat-label>
        <input matInput placeholder="limite de cupon" name="pricePerChild" type="text" [(ngModel)]="object.limit">
        <span matPrefix>&nbsp;</span>
      </mat-form-field>

      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Promoción</mat-label>
        <textarea matInput placeholder="Promoción" name="promotion_description" [(ngModel)]="object.promotion_description" [formControl]="complexForm.controls['promotion_description']"></textarea>
        <mat-error *ngIf="complexForm.controls['promotion_description'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Condiciones</mat-label>
        <textarea matInput placeholder="Descripción" name="terms" [(ngModel)]="object.terms" [formControl]="complexForm.controls['terms']"></textarea>
        <mat-error *ngIf="complexForm.controls['terms'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="outline">
        <mat-label>&nbsp;Tel.</mat-label>
        <input matInput placeholder="Tel." name="phone" [(ngModel)]="object.phone" [formControl]="complexForm.controls['phone']">
        <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="outline">
        <mat-label>&nbsp;Email</mat-label>
        <input matInput placeholder="Email" name="email" [(ngModel)]="object.email" [formControl]="complexForm.controls['email']">
        <mat-error *ngIf="complexForm.controls['email'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>


      <div class="col-12">
       <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
       <div class="cardImg">
         <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
         <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
         <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
         <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
         <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
         <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
       </div>
     </div>

    </div>
    <div class="row justify-content-between mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;"   (click)="viewAfther();">Atras</button>
      </div>
      <div class="col-4">
        <button mat-flat-button color="primary" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
