
<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva empresa</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-2">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field appearance="outline" class="col-12 col-md-10">
        <mat-label>Nombre.</mat-label>
        <input matInput placeholder="Nombre" name="name" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Email.</mat-label>
        <input matInput placeholder="Email" name="email" [formControl]="complexForm.controls['email']" [(ngModel)]="object.email" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['email'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Tel.</mat-label>
        <input matInput placeholder="Tel." name="phone" [formControl]="complexForm.controls['phone']" [(ngModel)]="object.phone" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Contraseña</mat-label>
        <input matInput type="password" placeholder="Contraseña." name="password" [formControl]="complexForm.controls['password']" [(ngModel)]="object.password" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['password'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Contraseña</mat-label>
        <input matInput type="password" placeholder="Contraseña." name="password_confirmation" [formControl]="complexForm.controls['password_confirmation']" [(ngModel)]="object.password_confirmation" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['password_confirmation'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Crear Usuario.</button>
      </div>
    </div>
  </div>
  <div class="col-11">
    <div class="row mt-2">
      <div class="col-12">
        <p>Listado de usuarios habilitados.</p>
      </div>
      <div class="col-12">
        <table class="table table-general">
          <thead class="header-table">
            <tr>
              <th>Nombre</th>
              <th>Usuario</th>
              <th>Creación</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let object of assigmentList; let i = index;">
              <td>
                {{object.user.name}}
              </td>
              <td>
                {{object.user.email}}
              </td>
              <td>
                {{object.user.created_at | date:'dd-MM-yyyy'}}
              </td>

              <td>
                <div class="table-actions">
                  <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>
