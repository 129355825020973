import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, 	HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Session } from './session.interface';
import server_url from './server_url';
import endpoint from './request_url';
import { Router } from '@angular/router';

const endpoints = endpoint;


@Injectable()
export class SessionService {
  private isUserLoggedIn:any;
  private token:any;
  private tokenType:any;
  private expiresAt:any;
  private roles = [];

  private sessionModel:Session = {
    user: {
      id: "",
      name: "",
      email: "",
      email_verified_at: false,
    }
  }
  private session = JSON.parse(JSON.stringify(this.sessionModel));
  constructor(protected http: HttpClient, private ngZone: NgZone, private router: Router) {
    this.isUserLoggedIn = false;
    this.token = '';
    this.tokenType = '';
    this.roles = [];
  }
  /**Instanciamos el backend para obtener los datos de sesión e inicializamos el objeto publico
  */
  private setLoggedIn(object:any) {
    // Instanciamos el backend para obtener los datos de sesión e inicializamos el objeto publico
    this.isUserLoggedIn = true;
    this.token = object.access_token;
    this.tokenType = object.token_type;
    this.expiresAt = object.expires_at;
    this.roles = object.roles
    sessionStorage.setItem('isUserLoggedIn', this.isUserLoggedIn);
    sessionStorage.setItem('token', this.token);
    sessionStorage.setItem('tokenType', this.tokenType);
    sessionStorage.setItem('expiresAt', this.expiresAt);
    sessionStorage.setItem('roles', JSON.stringify(this.roles));
  }
  /**función para limpiar todos los objetos de sesión
  */
  private setLoggout() {
    this.isUserLoggedIn = false;
    this.token = '';
    this.tokenType = '';
    this.expiresAt = [];
    this.roles = [];
    this.session = JSON.parse(JSON.stringify(this.sessionModel));
    sessionStorage.setItem('session',JSON.stringify(this.session));
    sessionStorage.setItem('isUserLoggedIn', this.isUserLoggedIn);
    sessionStorage.setItem('token', this.token);
    sessionStorage.setItem('tokenType', this.tokenType);
    sessionStorage.setItem('expiresAt', this.expiresAt);
    sessionStorage.setItem('roles', JSON.stringify(this.roles));
  }

  /** función para setear los objetos de sesión
  */
  private setSession(object:any) {
    this.session = JSON.parse(JSON.stringify(this.sessionModel));
    this.session.user = object;
    sessionStorage.setItem('session',JSON.stringify(this.session));
  }
  /** funcion para determinar si hay un usuario logueado en el sistema
  */
  getLoggedIn() {
    return this.isUserLoggedIn;
  }
  /**funcion para obtener la session del sistema
  */
  getSession() {
    return this.session;
  }
  /** Metodo para obtener los roles de un usuario
  */
  getRoles(){
    return this.roles;
  }


  /**
   * Función para obtener el recurso del listado de enpoints para consulta al backend
   */
  getEnpoint(code: string) {
    //buscamos el code en el compendio de urls
    let resourse = endpoints.find(x => x.code == code);

    //variable que contendra el path a llamar por el http metodo get
    if (resourse === undefined) {
      console.log('Error: La url que desea accesar no esta definida');
      return resourse;
    } else {
      return resourse.endpoint;
    }
  }

  /**función para realizar login
  *
  */
  login(object:any) {
    let parameters = {
      email: object.email,
      password: object.password,
      remember_me: object.remember_me
    };
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With':'XMLHttpRequets'  });
    let httpOptions = {
      headers: headers
    };
    return new Promise((resolve,reject) => {
      this.http.post(server_url + this.getEnpoint('auth:login'), parameters, httpOptions)
        .subscribe((data:any) => {
          this.setLoggedIn(data);
          headers = new HttpHeaders({
            'Content-Type': 'application/json' ,
            'Authorization': this.tokenType + " " + this.token
          });
          httpOptions = {
            headers: headers
          };
          this.http.get(server_url + this.getEnpoint('auth:user'), httpOptions)
            .subscribe((data:any) => {
              console.log('auth:user',data);
              this.setSession(data);
              resolve({transaction: 'ok', object: this.getSession()});
            }, error => {
              console.log("Error:login:001 ", error);
              reject(error);
            });
        }, error => {
          console.log("Error:login:002 ", error);
          reject(error);
        });
    });
  }

  /*
  * Método para realizar login con apple
  */
  loginWithGoogle(object:any){
    let parameters = object;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With':'XMLHttpRequets'  });
    let httpOptions = {
      headers: headers
    };
    return new Promise((resolve,reject) => {
      this.http.post(server_url + this.getEnpoint('auth:loginWithGoogle'), parameters, httpOptions)
        .subscribe((data:any) => {
          this.setLoggedIn(data);
          headers = new HttpHeaders({
            'Content-Type': 'application/json' ,
            'Authorization': this.tokenType + " " + this.token
          });
          httpOptions = {
            headers: headers
          };
          this.http.get(server_url + this.getEnpoint('auth:user'), httpOptions)
            .subscribe((data:any) => {
              // console.log('auth:user',data);
              this.setSession(data);
              resolve({transaction: 'ok', object: this.getSession()});
            }, error => {
              console.log("Error:loginWithGoogle:001 ", error);
              reject(error);
            });
        }, error => {
          console.log("Error:loginWithGoogle:002 ", error);
          reject(error);
        });
    });
  }

  /*
  * Método para realizar login con apple
  */
  loginWithFacebook(object:any){
    let parameters = object;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With':'XMLHttpRequets'  });
    let httpOptions = {
      headers: headers
    };
    return new Promise((resolve,reject) => {
      this.http.post(server_url + this.getEnpoint('auth:loginWithFacebook'), parameters, httpOptions)
        .subscribe((data:any) => {
          this.setLoggedIn(data);
          headers = new HttpHeaders({
            'Content-Type': 'application/json' ,
            'Authorization': this.tokenType + " " + this.token
          });
          httpOptions = {
            headers: headers
          };
          this.http.get(server_url + this.getEnpoint('auth:user'), httpOptions)
            .subscribe((data:any) => {
              // console.log('auth:user',data);
              this.setSession(data);
              resolve({transaction: 'ok', object: this.getSession()});
            }, error => {
              console.log("Error:loginWithFacebook:001 ", error);
              reject(error);
            });
        }, error => {
          console.log("Error:loginWithFacebook:002 ", error);
          reject(error);
        });
    });
  }

  /**función para realizar consultas al backend
  * @param code código para consultar al backend
  * @param object objeto a enviar al backEnd
  */
  postRequest(code: string, object:any): Observable<Response> {
    let path = server_url + this.getEnpoint(code);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.tokenType + " " + this.token
   });
    const httpOptions = {
      headers: headers
    };
    return Observable.create((observer:any) => {
      this.http.post(path, object, httpOptions)
        .subscribe((response) => {
          let answer:any = response;
          if (answer.transaction === 'ok') {
            observer.next(answer);
            observer.complete();
          } else {
            observer.error(answer);
          }
        }, error => {
          console.log(error);
          observer.error(error);
        });
    });
  }

  /**función para realizar consultas al backend en modo anonymous
  * @param code código para consultar al backend
  * @param object objeto a enviar al backEnd
  */
  postRequestAnonimus(code: string, object:any): Observable<Response> {
    let path = server_url + this.getEnpoint(code);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
   });
    const httpOptions = {
      headers: headers
    };
    return Observable.create((observer:any) => {
      this.http.post(path,object, httpOptions)
        .subscribe((response) => {
          let answer:any = response;
          if (answer.transaction === 'ok') {
            observer.next(answer);
            observer.complete();
          } else {
            observer.error(answer);
          }
        }, error => {
          console.log(error);
          observer.error(error);
        });
    });
  }

  /*
  funcion para cerrar session
  */
  logout(){
    return new Promise((resolve,reject)=>{
      let path = server_url + this.getEnpoint('auth:logout');
      let headers = new HttpHeaders({
        'Content-Type': 'application/json', 'X-Requested-With':'XMLHttpRequets',
        'Authorization': this.tokenType + " " + this.token
        });
      let httpOptions = {
        headers: headers
      };
      this.http.get(path, httpOptions)
        .subscribe((response) => {
          let answer:any = response;
          this.isUserLoggedIn = false;
          this.setLoggout();
          sessionStorage.clear();
          resolve({transaction: 'ok', message:'Session terminada.'});
        }, error => {
          console.log(error);
          reject(error);
        });
    })
  }

  /*
  funcion para cargar variables de session despues de un reload.
  */
  realodSession(){
    return new Promise((resolve)=>{
      this.isUserLoggedIn = sessionStorage.getItem('isUserLoggedIn');
      this.token = sessionStorage.getItem('token');
      this.tokenType = sessionStorage.getItem('tokenType');
      this.expiresAt = sessionStorage.getItem('expiresAt');
      let ax1:any = sessionStorage.getItem('session');
      let ax2:any = sessionStorage.getItem('roles');
      this.session = JSON.parse(ax1);
      this.roles = JSON.parse(ax2);
      // console.log(this.isUserLoggedIn);
      if( this.isUserLoggedIn != null && this.token != null && this.tokenType != null && this.expiresAt != null && this.session != null ){
        if(this.isUserLoggedIn == true || this.isUserLoggedIn == 'true'){
          // console.log("WTF!!!")
          resolve(true);
        }else{
          // console.log("AHEBR!!!")
          resolve(false);
        }
      }else{
        resolve(false);
      }
    });
  }

  /**función para realizar consultas al backend para subir un archivo
  * @param code código para consultar al backend
  * @param object objeto a enviar al backEnd
  */
  uploadFile(code: string, object:any): Observable<Response> {
    let path = server_url + this.getEnpoint(code);
    let headers = new HttpHeaders({
      // 'Content-Type':'multipart/form-data',
      'Authorization': this.tokenType + " " + this.token
   });
    const httpOptions = {
      headers: headers
    };
    return Observable.create((observer:any) => {
      this.http.post(path, object, httpOptions)
        .subscribe((response) => {
          let answer:any = response;
          if (answer.transaction === 'ok') {
            console.log("Request :: ", path);
            observer.next(answer);
            observer.complete();
          } else {
            observer.error(answer);
          }
        }, error => {
          console.log(error);
          observer.error(error);
        });
    });
  }

}
