<app-menu></app-menu>
<div class="card conten text-center downMenu">
    <div class="card-body">
      <div class="row justify-content-center m-0" style="padding-top: 1rem; padding-bottom: 1rem;">
          <div class="col-md-8 col-11" style="padding: 1rem 0rem;">
              <p  class="tittle-search">Todos los cupones</p>
              <p  class="tittle-search mt-2 mt-md-5">de tus tiendas favoritas </p>
          </div>
      </div>
      <div class="row justify-content-center">
        <div class=" col-md-8 p-0">
          <input class="form-control me-2" type="search" placeholder="Buscar cupones" aria-label="Search"  [(ngModel)]="couponSearchingMin.filter" (ngModelChange)="search();" [matAutocomplete]="autoProduct">
        </div>
        <button class="btn-buscar " type="submit"><span class="material-icons" style="font-size: 18px;">search</span></button>
        <mat-autocomplete autoActiveFirstOption #autoProduct="matAutocomplete">
          <mat-option *ngFor="let object of couponSearchList" [value]="object.company.name + ' ' + object.promotion_description" (click)="couponSelected(object);">
            <img class="example-option-img" aria-hidden src="{{object.img}}" height="25" style="padding-right: 15px;">
            <span> <strong style="font-size: medium; color: #706ed9;"> {{object.company.name}} </strong> {{object.promotion_description}} </span>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
</div>
<div class="container mt-1">
  <div class="row justify-content-end mt-2 mb-2">
    <mat-form-field class="form-select tama col-md-3 col-12 color">
      <mat-label>Categoria</mat-label>
      <mat-select name="category" (selectionChange)="changeCategoryTosearch();" [(ngModel)]="couponSearchingMin.category.id">
        <mat-option value="0">Todas</mat-option>
        <mat-option *ngFor="let object of categoryList" [value]="object.id">{{object.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="text-align:center">
      <p class="tittle mt-3">Lo más destacado</p>
  </div>
  <div class="row justify-content-center">
    <div class="col-9 col-md-3 text-center mb-3" *ngFor="let object of list; let i = index;">
      <mat-card class="p-0 mt-1 product-list" [routerLink]="['/coupons/details', object.id]" style="height: 20rem !important;">
        <div class="frame">
          <img [src]="object.img">
        </div>
        <!-- <div class="btn-product">
          <button mat-raised-button color="accent" class="add-shopping text-uppercase">Ver detalle</button>
        </div> -->
        <div class="card-body">
          <p class="mb-1">{{object.company.name}}</p>
          <small class="text-uppercase text-secondary-min">{{object.category.name}}</small>
          <!-- <a [routerLink]="['/coupons/details', object.id]" class="btn btn-outline-primary btn-block">Ver detalles</a> -->
        </div>
      </mat-card>
    </div>
  </div>
</div>
<app-footer></app-footer>
