import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-superuser-packages-update',
  templateUrl: './superuser-packages-update.component.html',
  styleUrls: ['./superuser-packages-update.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SuperuserPackagesUpdateComponent implements OnInit {
  complexForm: FormGroup;
  packageModel = {
    id:'',
    name:'',
    description:'',
    quantity:0,
    vigency:'',
    free:false,
    annual:false,
    price:''
  };

  object = JSON.parse(JSON.stringify(this.packageModel));
  metadata = {
    vigency:''
  }

  constructor(
    public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SuperuserPackagesUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
      'quantity': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    if(this.data != 'new') {
      this.loadingService.show(true, "Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequest('package:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.vigency = moment(this.object.vigency).toISOString();
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: package:get',error)
       })
    }
  }

  /**
   * funcion para guardar
   */
  send(object){
    this.loadingService.show(true,'Guardando...');
    if(this.metadata.vigency != ''){
      this.object.vigency = moment(this.metadata.vigency).format("YYYY-MM-DD HH:mm");
    }else{
      this.object.vigency = moment().format("YYYY-MM-DD HH:mm");
    }

    this.session.postRequest("package:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        object.id = data.object.id;

        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: package:update",error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'package:001';
    }
    this.dialogRef.close(object);
  }

}
