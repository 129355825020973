import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  categoryMin = {
    max: 10,
    page: 1,
    status:true
  }
  list = [];

  couponSearchingMin = {
    filter:'',
    max: 20,
    page: 1,
    status:true,
    category:{
      id:0
    }
  };
  couponSearchList = []

  constructor(private session: SessionService,
    public loadding:LoadingService,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(){
    this.session.postRequest('category:findAllByStatus', this.categoryMin).subscribe((data: any) => {
      this.list = data.object.instanceList;
      // this.paginator.total = data.object.total;
    }, error => {
      console.log('error:category:findAllByStatus', error);
    });
  }

  search(){
    if(this.couponSearchingMin.filter.length > 2){
      this.session.postRequestAnonimus("coupon:searching",this.couponSearchingMin).subscribe((data:any)=>{
        this.couponSearchList = data.object.instanceList;
      },error=>{
        console.log("error:category:findAllByStatus",error);
      })
    }else{
      this.couponSearchList = [];
    }

  }


  couponSelected(object){
    // console.log(object);
    this.router.navigate(['/coupons/details/'+object.id]);

  }

}
