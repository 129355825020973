<app-superuser-toolbar></app-superuser-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Uusario</h2>
        </div>
        <div class="col-3 text-right">
        </div>
      </div>
      <div class="row">
        <div class="col-6">

        </div>
        <mat-form-field class="col-6" style="text-aling: right; width: 100%;">
          <input matInput placeholder="Buscar" [(ngModel)]="profileMin.filter" (ngModelChange)="search()" >
        </mat-form-field>
      </div>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Cupones descargados</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>
              {{object.name + ' ' + object.lastname}}
            </td>
            <td>
              {{object.email}}
            </td>
            <td>
              {{object.cupons}}
            </td>

            <td>
              <div class="table-actions">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
