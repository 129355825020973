import { Component, OnInit } from '@angular/core';

import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-coupons',
  templateUrl: './web-coupons.component.html',
  styleUrls: ['./web-coupons.component.css']
})
export class WebCouponsComponent implements OnInit {

  couponMin = {
    max: 12,
    page: 1,
    status:true
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  categoryList = [];
  categoryMin = {
    max: 100,
    page: 1,
    status:true
  };

  couponSearchingMin = {
    filter:'',
    max: 20,
    page: 1,
    status:true,
    category:{
      id:0
    }
  };

  couponSearchList = []
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public router: Router 
            ) { }

  ngOnInit(): void {
    this.getCoupons();
    this.getCategories();
    //this.getCompany();
  }
/**función para obtener el listado de cupones
  */

 getCoupons(){
   //this.couponMin.company.id = '1';
   this.session.postRequestAnonimus('coupon:findAllRamdom', this.couponMin).subscribe((data: any) => {
    this.list = data.object.instanceList;
    this.paginator.total = data.object.total;
  }, error => {
    console.log('error:coupon:findAllRamdom', error);
  });
}

getCategories(){
  this.session.postRequest("category:findAllByStatus",this.categoryMin).subscribe((data:any)=>{
    this.categoryList = data.object.instanceList;
  },error=>{
    console.log("error:category:findAllByStatus",error);
  })
}
// funcion para obtener los datos del paginado.
onPaginateChange(event){
  this.couponMin.max = event.pageSize;
  this.couponMin.page = event.pageIndex + 1;
  //this.getCoupons();
}

  search(){
    this.loadding.wait();
    this.session.postRequestAnonimus("coupon:searching",this.couponSearchingMin).subscribe((data:any)=>{
      this.couponSearchList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:category:findAllByStatus",error);
      this.loadding.hide();
    })
  }

  changeCategoryTosearch(){
    // console.log("this.couponSearchingMin",this.couponSearchingMin);
    this.search();
  }

  couponSelected(object){
    // console.log(object);
    this.router.navigate(['/coupons/details/'+object.id]);

  }
}
