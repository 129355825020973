import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro-empresas',
  templateUrl: './registro-empresas.component.html',
  styleUrls: ['./registro-empresas.component.css']
})
export class RegistroEmpresasComponent implements OnInit {
  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;
  companyModel = {
    id:'',
    name_contact:'',
    email_contact:'',
    phone_contact:'',
    social_reason:'',
    name:'',
    slogan:'',
    giro:'',
    area:'',
    abstract:'',
    whastapp:'',
    address:'',
    website:'',
    phone:'',
    email:'',
    facebook:'',
    instagram:'',
    twitter:'',
    linkedin:'',
    logotipo:'',
    status:true,
    statusCompany:'Nuevo'
  }
  metadata = {
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false,
    registrate:false
  }

  object = JSON.parse(JSON.stringify(this.companyModel));

  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public router:Router) {
    this.complexForm = fb.group({
      'name_contact': [null, Validators.compose([Validators.required])],
      'email_contact': [null, Validators.compose([Validators.required])],
      'phone_contact': [null, Validators.compose([Validators.required])],
      'social_reason': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      // 'slogan': [null, Validators.compose([Validators.required])],
      'giro': [null, Validators.compose([Validators.required])],
      // 'area': [null, Validators.compose([Validators.required])],
      // 'abstract': [null, Validators.compose([Validators.required])],
      'whastapp': [null, Validators.compose([Validators.required])],
      'address': [null, Validators.compose([Validators.required])],
      // 'website': [null, Validators.compose([Validators.required])],
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
  }


  /**
   * funcion para guardar
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("requestcompany:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        object.id = data.object.id;
        this.metadata.registrate = true;
        this.loadingService.hide();
        this.session.postRequest("requestcompany:sendEmailNewCompany",object).subscribe(
          (data:any) => {
          },
          error => {
            console.log("Error: requestcompany:sendEmailNewCompany",error);
            this.loadingService.hide();
          }
        );
        // this.router.navigate(['/thanksRequest']);
      },
      error => {
        console.log("Error: requestcompany:update",error);
        this.loadingService.hide();
      }
    );
  }

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     // console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     //subimos el archivo a la BD
     this.uploadImg();
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile("requestcompany:upload",formData).subscribe(async(data:any)=>{
        this.object.logotipo = "https://www.cuponeravirtual.com.mx/public/company/"+data.object.name;
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
      },error=>{
        console.log("requestcompany:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  download(){
    let elem = document.createElement('a');
    elem.target = '_blank';
    elem.href = this.object.logotipo;
    elem.setAttribute('download', this.object.logotipo);
    document.body.appendChild(elem);
    elem.click();
  }

}
