import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-company-branch',
  templateUrl: './company-branch.component.html',
  styleUrls: ['./company-branch.component.css']
})
export class CompanyBranchComponent implements OnInit {
branchList = [];
company = {
  id:'',
  name:'',
  address:''
}

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CompanyBranchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.data.id != 'new') {
      this.loadingService.show(true,"Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequestAnonimus('company:get',this.data).subscribe((data:any)=>{
         this.company = data.object;
         this.session.postRequestAnonimus('branch:findAllByCompany',{company:{id:this.data.id}}).subscribe((data:any)=>{
           for(let i = 0 ; i  < data.object.instanceList.length; i++){
             data.object.instanceList[i].frameSRC = this.sanitizer.bypassSecurityTrustHtml(data.object.instanceList[i].iframe);
           }
           this.branchList = data.object.instanceList;
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           console.log('Error: branch:findAllByCompany',error)
         })
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: branch:findAllByCompany',error)
       })

    }
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'company:001';
    }
    this.dialogRef.close(object);
  }

  goToLink(url: string){
      window.open(url, "_blank");
  }

  goToLinkCompany(url: string){
    let newURL =  'https://maps.google.com/?q='+url;
      window.open(newURL, "_blank");
  }


}
