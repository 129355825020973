import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-superuser-coupons-view',
  templateUrl: './superuser-coupons-view.component.html',
  styleUrls: ['./superuser-coupons-view.component.css']
})
export class SuperuserCouponsViewComponent implements OnInit {
  profileCouponMin = {
    max: 10,
    page: 1,
    status:true,
    coupon:{
      id:''
    }
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SuperuserCouponsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(){
    this.loadingService.show(true,"Espere un momento...");
    this.profileCouponMin.coupon.id = this.data.id;
    this.session.postRequest("profileCoupon:findAllByCoupon",this.profileCouponMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.profileCouponMin.max = event.pageSize;
    this.profileCouponMin.page = event.pageIndex + 1;
    this.getList();
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'people:001';
    }
    this.dialogRef.close(object);
  }

}
