import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-superuser-branch-update',
  templateUrl: './superuser-branch-update.component.html',
  styleUrls: ['./superuser-branch-update.component.css']
})
export class SuperuserBranchUpdateComponent implements OnInit {
  complexForm: FormGroup;
  branchModel = {
    id:'',
    name:'',
    address:'',
    iframe:'',
    lat:'',
    lgt:'',
    company:{
      id:''
    }
  };
  object = JSON.parse(JSON.stringify(this.branchModel));
  constructor(
    public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SuperuserBranchUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    if(this.data.branchID != 'new') {
      this.loadingService.show(true,"Espere un momento...");
       // cargamos los datos de la BD
       this.session.postRequest('branch:get',{id:this.data.branchID}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: company:get',error)
       })
    }
  }

  /**
   * funcion para guardar
   */
  send(){
    this.loadingService.show(true,'Guardando registro...');
    this.object.company.id = this.data.companyID;
    this.session.postRequest("branch:update",this.object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.object.id = data.object.id;

        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: branch:update",error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'branch:001';
    }
    this.dialogRef.close(object);
  }

}
