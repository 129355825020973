<app-menu></app-menu>
  <div class="container"  style="padding-top: 10%;">
    <div style="text-align:center">
        <p class="tittle">Mis Cupones</p>
    </div>
    <div class="row">
      <div class="col-md-3 text-center mb-3" *ngFor="let object of list; let i = index;">
        <mat-card class="p-0 mt-1 product-list" style="height: 20rem !important;">
          <img [src]="object.coupon.img" class="img-list">
          <div class="card-body">
            <p><strong>{{object.coupon.promotion_description}}</strong></p>
            <!-- <small class="text-uppercase text-secondary">{{object.coupon.email}}</small><br> -->
            <small class="text-uppercase text-secondary">{{object.coupon.vigency}}</small>
            <!-- <a [routerLink]="['/coupons/details', object.id]" class="btn btn-outline-primary btn-block">Ver detalles</a> -->
            <span class="usedCss" *ngIf="object.used">Cupón utilizado</span>
          </div>
        </mat-card>
      </div>
    </div>
    <!-- <div class="card-columns" style="padding-top: 5%;">
      <div class="card text-center" *ngFor="let object of list; let i = index;">
        <div class="img-box">
          <img [src]="object.coupon.img" style="width: 8rem;" class="card-img-top">
        </div>
        <div class="card-body">

          <p>Vigencia: <strong>{{object.coupon.vigency}}</strong></p>
          <p><strong>{{object.coupon.promotion_description}}</strong> </p>
          <p><strong>{{object.coupon.email}}</strong></p>
        </div>
      </div>
    </div> -->
  </div>

<br><br>
<app-footer></app-footer>
