import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  metadata = {
    isLoggued:false,
    username:''
  }

  constructor(public session:SessionService, public router:Router, public snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    // console.log(this.session.getSession());
    this.metadata.isLoggued = this.session.getLoggedIn();
    if(this.metadata.isLoggued){
      this.metadata.username = this.session.getSession().user.name;
    }
  }


  logout(){
    this.session.logout().then((data)=>{
      this.snackBar.open('Nos vemos pronto', 'Aceptar', {duration: 5000});
      this.router.navigate(['/coupons']);
      window.location.reload();
    }).catch(e=>{
      this.snackBar.open('Nos vemos pronto', 'Aceptar', {duration: 5000});
      this.router.navigate(['/coupons']);
      window.location.reload();
    })
  }

  sendToLogin(){
    this.router.navigate(['/login']);
  }


}
