<div class="row justify-content-center">
  <div class="col-12">
    <button mat-icon-button class="naturaColor"  style="font-size: smaller;float: right;" matTooltip="Sucursales" (click)="close(true);" ><mat-icon>clear</mat-icon></button>
  </div>
  <div class="col-12 col-md-11 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Sucursales</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" (click)="update('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva sucursal</button>
      </div>
    </div>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Nombre</th>
          <th>Dirección</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of branchList; let i = index;">
          <td>
            {{object.name}}
          </td>
          <td>
            {{object.address}}
          </td>

          <td>
            <div class="table-actions">
              <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="update(object.id);" ><mat-icon>edit</mat-icon></button>
              <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
