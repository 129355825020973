import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuperuserRequestcompanyUpdateComponent } from './superuser-requestcompany-update/superuser-requestcompany-update.component';

@Component({
  selector: 'app-superuser-requestcompany',
  templateUrl: './superuser-requestcompany.component.html',
  styleUrls: ['./superuser-requestcompany.component.css']
})
export class SuperuserRequestcompanyComponent implements OnInit {
  requestcompanyMin = {
    max: 10,
    page: 1,
    status:true,
    statusCompany:'Nuevo'
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  statusList = [
    'Nuevo',
    'Aceptada',
    'Rechazada'
  ];
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getList();
  }

  /*funcionalidad para obtener las solicitudes por tab*/
  changeTab(ev){
    console.log(ev);
    this.requestcompanyMin.statusCompany = ev.tab.textLabel;
    this.getList();
  }


  /**función para obtener el listado de categorias
  */
  getList(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("requestcompany:findAllByStatus",this.requestcompanyMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loadding.hide();
    },error=>{
      console.log("error:requestcompany:list",error);
      this.loadding.hide();
    })
  }

  /** funcionalidad para crear una categoria
  */
  update(uuid:string){
    const dialogRef = this.dialog.open(SuperuserRequestcompanyUpdateComponent, {
      width: '70%',
      height: '95%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event:any){
    this.requestcompanyMin.max = event.pageSize;
    this.requestcompanyMin.page = event.pageIndex + 1;
    this.getList();
  }

}
