import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RegistroEmpresasComponent } from '../registro-empresas/registro-empresas.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { LoadingService } from '../../directive/loading/loading.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {
  empresas = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  companyMin = {
    max: 100,
    page: 1,
    status:true
  }
  list:any = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  categoryList = [];
  categoryMin = {
    max: 100,
    page: 1,
    status:true
  };
  companySearchMin = {
    filter:'',
    max: 10,
    page: 1,
    status:true
  }

  companySearchList = [];
  constructor(private session: SessionService,
              public loadding: LoadingService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public router: Router
            ) { }

  ngOnInit(): void {
    this.getCompany();
    // this.getCategories();
  }

  getCompany(){
    this.session.postRequest('company:findAllByStatus', this.companyMin).subscribe((data: any) => {
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    }, error => {
      console.log('error:company:findAllByStatus', error);
    });
  }



  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.companyMin.max = event.pageSize;
    this.companyMin.page = event.pageIndex + 1;
    this.getCompany();
  }

  search(){
    this.loadding.wait();
    this.session.postRequestAnonimus("company:searching",this.companySearchMin).subscribe((data:any)=>{
      this.companySearchList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:company:findAllByStatus",error);
      this.loadding.hide();
    })
  }


  companySelected(object){
    // console.log(object);
    this.router.navigate(['/coupons/company/'+object.id]);

  }
}
