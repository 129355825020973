<button mat-icon-button (click)="close(false)" color="accent" style="float: right;">
        <mat-icon>clear</mat-icon>
</button>
<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva empresa</h3>
    <p>Todos los campos son importantes.</p>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-2">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Nombre Empresa.</mat-label>
        <input matInput placeholder="Nombre" name="name" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Razón Social.</mat-label>
        <input matInput placeholder="Razon Social" name="social_reason" [(ngModel)]="object.social_reason" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['social_reason'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Giro.</mat-label>
        <input matInput placeholder="Giro" name="giro" [(ngModel)]="object.giro" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['giro'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Especialidad.</mat-label>
        <input matInput placeholder="area" name="area"  [(ngModel)]="object.area" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['area'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Eslogan</mat-label>
        <textarea matInput placeholder="Eslogan" name="slogan" [(ngModel)]="object.slogan" ></textarea>
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['slogan'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Breve Reseña</mat-label>
        <textarea matInput placeholder="Breve Reseña" name="abstract" [(ngModel)]="object.abstract" ></textarea>
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['abstract'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>


      <p class="col-9">Datos de contacto.</p>
      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>WhastApp.</mat-label>
        <input matInput placeholder="WhastApp" name="whastapp"  [(ngModel)]="object.whastapp" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['whastapp'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>website.</mat-label>
        <span matPrefix>https:// &nbsp;</span>
        <input matInput placeholder="website" name="website" [(ngModel)]="object.website" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['website'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Tel.</mat-label>
        <input matInput placeholder="Tel" name="phone" [(ngModel)]="object.phone" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['phone'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Email.</mat-label>
        <input matInput placeholder="Email" name="email" [(ngModel)]="object.email"  [formControl]="complexForm.controls['email']"  required >
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['email'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Dirección</mat-label>
        <textarea matInput placeholder="Dirección" name="address"  [(ngModel)]="object.address" ></textarea>
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['address'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Facebook.</mat-label>
        <input matInput placeholder="Facebook" name="facebook" [(ngModel)]="object.facebook">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Instagram.</mat-label>
        <input matInput placeholder="Instagram" name="instagram"  [(ngModel)]="object.instagram">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-5">
        <mat-label>Twitter.</mat-label>
        <input matInput placeholder="Twitter" name="twitter" [(ngModel)]="object.twitter">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Linkedin.</mat-label>
        <input matInput placeholder="linkedin" name="linkedin" [(ngModel)]="object.linkedin">
      </mat-form-field>

      <p class="col-9">Personal de contacto.</p>

      <mat-form-field appearance="outline" class="col-12 col-md-9">
        <mat-label>Nombre Titular.</mat-label>
        <input matInput placeholder="Nombre" name="name_contact" [(ngModel)]="object.name_contact" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['name_contact'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Tel.</mat-label>
        <input matInput placeholder="Tel" name="phone_contact"  [(ngModel)]="object.phone_contact" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['phone_contact'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-4">
        <mat-label>Email.</mat-label>
        <input matInput placeholder="Email" name="email_contact"  [(ngModel)]="object.email_contact" >
        <!-- <mat-hint>
          <mat-error *ngIf="complexForm.controls['email_contact'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint> -->
      </mat-form-field>


      <div class="col-8">
       <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
       <div class="cardImg">
         <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
         <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
         <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
         <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
         <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
         <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
       </div>
     </div>


    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]="complexForm.invalid"  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
