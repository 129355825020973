import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../service/session/session.service';
import { LoadingService } from '../directive/loading/loading.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageTool} from '../service/session/storage.tool';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  contacto;
  submitted = false;
  object = {
    email: '',
    password: '',
    remember_me: true
  };

  perfileModel = {
    id:'',
    name:'',
    lastname:'',
    phone:'',
    email:'',
    img:'',
    user:{
      id:''
    }
  }
  metadata = {
    tab:'Usuarios'
  }

  constructor(private router: Router,
              private session: SessionService,
              private formbuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private storage: StorageTool,
              public loading:LoadingService,
              private authService: SocialAuthService
            ) {
    this.contacto = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
  });
   }

  ngOnInit(): void {
  }
  get f() { return this.contacto.controls; }
  onSubmit(): void {
    this.submitted = true;

    if (this.contacto.invalid) {
        return;
    }
}

send(object: any){
  // console.log(object)
  // this.router.navigate(['/admin/historial/']);
  if (object.username !== '' && object.password !== ''){
    // Mandamos a invocar el servicio de login
    this.loading.show(true,"Espere un momento...");
    this.session.login(object).then( async (data:any)=> {
      if (data.transaction === 'ok') {
        this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
        if(this.object.remember_me){
        this.storage.saveAKeyOnTheStorage('object', this.object);
      }
        // tslint:disable-next-line:prefer-const
        let role = this.session.getRoles();
        // console.log(role[0]);
        switch(role[0]){
          case 'ADMINISTRATOR':
            this.router.navigate(['/superuser/company']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
            break;
          case 'CLIENT':
            this.router.navigate(['/web/coupons']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
            break;
          case 'COMPANY':
            this.router.navigate(['/company/coupons']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
            break;
          default:
            this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
            break;
        }
        this.loading.hide();
      } else {
        this.loading.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        console.log('Usuario o Contraseña incorrectos');
      }
    }).catch((error)=>{
      this.loading.hide();
      this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
      console.log('Error: ', error);
    });
  }
}

Registro(): void{
  this.router.navigate(['/registro']);
}

signInWithGoogle(): void {
  this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data:any)=>{
    // console.log("GOOOGE",data);
    let loginGoogleModel = {
      name:data.firstName,
      email:data.email,
      lastname:data.lastName,
      authToken:data.authToken,
      idToken:data.idToken,
      photoUrl:data.photoUrl,
      role:'CLIENT',
      googleId:data.id
    }
    this.loading.show(true,"Espere un momento...");
    this.session.loginWithGoogle(loginGoogleModel).then( async (data:any)=> {
      if (data.transaction === 'ok') {
        this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
        if(true){
          this.storage.saveAKeyOnTheStorage('object',{email:data.email,password:data.id,remember_me:true});
        }
        // tslint:disable-next-line:prefer-const
        let role = this.session.getRoles();
        // console.log(role[0]);
        switch(role[0]){
          case 'CLIENT':
            this.router.navigate(['/web/coupons']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
            break;
          default:
            this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
            break;
        }
        this.loading.hide();
      } else {
        this.loading.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        console.log('Usuario o Contraseña incorrectos');
      }
    }).catch((error)=>{
      this.loading.hide();
      this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
      console.log('Error: ', error);
    });
    // console.log(loginGoogleModel);
  }).catch(e=>{
    console.log(e);
  });
}

signInWithFacebook(): void {
  this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data:any)=>{
    console.log("Facebook",data);
    let email = ''
    if (data.email == undefined) {
      email = data.id;
    } else {
      email = data.email;
    }
    let loginFacebookModel = {
      name:data.firstName,
      email:email,
      lastname:data.lastName,
      authToken:data.authToken,
      photoUrl:data.photoUrl,
      role:'CLIENT',
      facebookId:data.id
    }
    this.loading.show(true,"Espere un momento...");
    this.session.loginWithFacebook(loginFacebookModel).then( async (data:any)=> {
      if (data.transaction === 'ok') {
        this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
        if(true){
          this.storage.saveAKeyOnTheStorage('object',{email:data.email,password:data.id,remember_me:true});
        }
        // tslint:disable-next-line:prefer-const
        let role = this.session.getRoles();
        // console.log(role[0]);
        switch(role[0]){
          case 'CLIENT':
            this.router.navigate(['/web/coupons']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
            break;
          default:
            this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
            break;
        }
        this.loading.hide();
      } else {
        this.loading.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        console.log('Usuario o Contraseña incorrectos');
      }
    }).catch((error)=>{
      this.loading.hide();
      this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
      console.log('Error: ', error);
    });
    // console.log(loginGoogleModel);
  }).catch(e=>{
    console.log(e);
  });
}

tabChange(ev){
  // console.log(ev);
  this.metadata.tab = ev.tab.textLabel;
}

}
